var miniBasketXhr;  //This is a global var to hold ajax request for min basket, in case we need to abort it.

/**
     * Split an array into chunks
     *
     * @param object myArray array to be chunked
     * @param integer chunk_size size of chunks
     * @returns {array}
*/
function chunkArray(myArray, chunk_size){
    var index = 0;
    var arrayLength = myArray.length;
    var tempArray = [];
    
    for (index = 0; index < arrayLength; index += chunk_size) {
        myChunk = myArray.slice(index, index+chunk_size);
        // Do something if you want with the group
        tempArray.push(myChunk);
    }

    return tempArray;
}

// ============================================== Get currency from dom and process  //

var setCurrency = {
    currencySymbol: '&pound;',
    init: function () {
        var me = setCurrency;
        me.currencySymbol = $('#mini-basket').data('currency-symbol');
    },
    processCurrency: function (value) {
        var me = setCurrency;
        value = (value * 1).toFixed(2);
        value = value.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"); // add commas
        return me.currencySymbol + '' + value;
    }
};


// ============================================== Mini Basket functions  //

var miniBasket = {
    closeTimer: null,
    init: function () {

        var me = miniBasket;
        var $target = $('#mini-basket');

        // Click basket in main nav
        $('.top-nav .nav-basket a').click(function (e) {
            e.preventDefault();
            if (!$('.page-checkout').length && !$('.page-basket').length) {
                me.show(false);
            }
        });

        // After we delete a row need to update Basket
        var afterRemove = function (thisArray) {
            // Run queue
            addToBasket.processArray(thisArray, true)
                .done(function () {
                    miniBasket.update(true, false); // showAfter, hideAfter
                });
        }

        // Remove item button
        $target.on('click', '.icon-del', function (e) {
            //delete a row from the mini basket.
            var $row = $(this).closest('.js-basket-row');
            var totalRows = $target.find('.js-basket-row').length;
            // Set totals back to 'pending'
            $target.find('.js-subtotal-amount').text('')
                .next('.js-ajax-spinner').addClass('ajax-spinner-show');
            //
            // First create object array
            var productArray = [{'id': $row.data('product-id'), 'quantity': 0}];
            //
            $row.fadeTo(200, 0.00, function () { //fade
                if (totalRows > 1) {
                    $(this).slideUp(200, function () { //slide up
                        afterRemove(productArray);
                    });
                } else {
                    afterRemove(productArray);
                }
            });
            //
            e.preventDefault();
            e.stopPropagation();
        });
        // Hide basket on click
        $target.click(function (e) {
            clearTimeout(me.closeTimer);
            me.hide();
        });

    },
    // get new miniBasket view
    update: function (showAfter, hideAfter) {

        var me = miniBasket;
        //
        if (typeof miniBasketXhr != 'undefined') {
            miniBasketXhr.abort();
        }
        //
        var date = new Date();
        //

        miniBasketXhr = $.get('/shop/mini_basket_view?time=' + date.getTime()).done(function (data) {
            $('.mini-basket-inner').html(data);

            // Get all mini basket prices
            me.getMiniQtyPrices().then(function () {
                // Update minibasket total
                me.calculateSubTotal();
                // Activate del buttons
                $('#mini-basket').find('.icon-del').removeClass('disabled');
            });

            if (showAfter) {
                me.show(hideAfter);
            }
        });

    },
    // show miniBasket
    show: function (hideAfter) {

        var me = miniBasket;
        //
        $('body').addClass('mini-basket-open');
        //
        if (hideAfter) {
            me.closeTimer = setTimeout(function () {
                me.hide();
            }, 3000);
        } else {
            clearTimeout(me.closeTimer);
        }

    },
    // Hide miniBasket
    hide: function () {
        var me = miniBasket;
        //
        clearTimeout(me.closeTimer);
        //
        $('body').addClass('mini-basket-closing');
        // Remove after fading out
        setTimeout(function () {
            $('body').removeClass('mini-basket-open');
            $('body').removeClass('mini-basket-closing');
        }, 400);
    },
    // Get the quantity prices for items in the minibasket
    getMiniQtyPrices: function () {

        var me = miniBasket;

        // Defferred object
        var defer = $.Deferred();

        // Build array of product dom objects
        var productObjs = [];
        $('.mini-basket .js-get-price').each(function () {
            productObjs.push($(this));
        });
        // We want to run each ajax call in series
        // so we build an array of functions
        var getProductsDeferred = [];
        //
        for (var i = 0; i < productObjs.length; i++) {
            getProductsDeferred.push(updatePagePrices.fetchProductPrice(productObjs[i]));
        }

        // Run all the functions in the array and when complete pass done up the chain
        $.when.apply($, getProductsDeferred)
            .done(function () {
                defer.resolve();
            });

        // Return deferred promise
        return defer.promise();
    },
    calculateSubTotal: function () {
        //console.log('calculateSubTotal');
        var me = miniBasket;
        var $target = $('#mini-basket');

        var subTotal = 0;
        var totalItems = 0;

        $target.find('.js-basket-row').each(function (i) {
            var thisQty = $(this).find('.js-quantity').text();
            // remove everything except numerical and decimal
            thisQty = thisQty.replace(/[^0-9.]/g, "");
            // remove decimal
            if (thisQty != '') {
                thisQty = Math.floor(thisQty);
            }
            //
            subTotal += ($(this).find('.js-get-price').data('price') * thisQty);
            totalItems += thisQty;
        });

        var priceText = 'Unavailable';
        if (!isNaN(subTotal)) {
            priceText = setCurrency.processCurrency(subTotal);
        }

        // Empty mini basket on successful checkout
        if ($('section#page-checkout-success').length) {
            totalItems = 0;
        }

        // hide Spinner
        $('.nav-basket .js-ajax-spinner').removeClass('ajax-spinner-show');
        // Update header items
        if (totalItems == 0) {
            $('.js-head-items, .js-head-subtotal').hide();
            $('.js-head-basket-empty').show();
            // Disable checkout button
            $('.js-mini-basket-nav').hide();
        } else {
            var plural = totalItems > 1 ? 's' : '';
            $('.js-head-items').text('Item' + plural + ': ' + totalItems).show();
            $('.js-head-subtotal').text(priceText).show();
            $('.js-head-basket-empty').hide();
            // Enable checkout button
            $('.js-mini-basket-nav').show();
        }

        // Update minbasket price
        $target.find('.js-subtotal-amount').text(priceText)
            .next('.js-ajax-spinner').removeClass('ajax-spinner-show');

    }
}


// ============================================== Product Pricing functions  //

// For getting all the base prices for products on a page

var updatePagePrices = {
    init: function () {
        var me = updatePagePrices;

        // Disable any add to basket buttons while we get prices
        $('.add-to-basket .btn').prop('disabled', true);

        // Disable basket change quantity while we get prices
        $('.js-quantity-edit').prop('disabled', true);

        // Get all the prices on a page
        me.buildPriceList().then(function () {
            console.log('updatePagePrices complete');

            // Update minibasket total
            miniBasket.calculateSubTotal();

            // Enable remove item buttons
            me.enableDel();

            // If we're on a basket page start processing totals
            if ($('.page-basket').length) {
                basketPrices.init();
            }

        });

    },
    // Build a list of price objects to send to AJAX function
    buildPriceList: function () {
        var me = updatePagePrices;

        // Defferred object
        var defer = $.Deferred();
        var deferB = $.Deferred();
        

        // Build array of ids and quantities
        var productObjs = [];
        $('.js-get-price').each(function () {
            var tempObj = {};
            tempObj.id = $(this).data('product-id');
            // Element might have a quantity set
            tempObj.quantity = 1;
            var thisProductQty = $(this).data('product-qty');
            if(thisProductQty && thisProductQty > 1 ){
                tempObj.quantity = thisProductQty;
            }
            productObjs.push(tempObj);
        });

        // Break that array into chunks
        var chunkLimit = 20;
        var chunkedArray = chunkArray(productObjs, chunkLimit);

        // We want to run each ajax call in series
        // so we build an array of functions
        var getProductsDeferred = [];
        //
        //for (var i = 0; i < chunkedArray.length; i++) {
           // getProductsDeferred.push(me.fetchProductPriceBatch(chunkedArray[i]));
        //}

        function ajaxCalls(){
            return[
                me.fetchProductPriceBatch(chunkedArray[0])
            ]
        }

        var looper = deferB.resolve();

        // go through each item and call the ajax function
        $.when.apply($, $.map(chunkedArray, function(item, i) {
            looper = looper.then(function() {
                // trigger ajax call with item data
                return me.fetchProductPriceBatch(item);
            });
        
            return looper;
        })).then(function() {
            // run this after all ajax calls have completed
            defer.resolve();
        });

        // Run all the functions in the array and when complete pass done up the chain
        /*$.when.apply( ajaxCalls() ).then(function(){
            me.fetchProductPriceBatch(chunkedArray[1]).then(function(){
                me.fetchProductPriceBatch(chunkedArray[2]).then(function(){
                    me.fetchProductPriceBatch(chunkedArray[3]).then(function(){
                        defer.resolve();
                    });
                });
            });
        });*/
        /*$.when.apply($, getProductsDeferred)
            .done(function () {
                defer.resolve();
            });*/
        
        // Return deferred promise
        return defer.promise();

    },
    // Fetch a single product price based on passed in Dom object
    fetchProductPrice: function ($productItem) {

        // Defferred object
        var defer = $.Deferred();

        // Get date for caching
        var date = new Date();

        // Get product ID
        var productId = $productItem.data('product-id');

        // Get product quantity (stored in data attr)
        var productQty = 1;
        if ($productItem.data('product-qty') > 1) {
            productQty = $productItem.data('product-qty');
        }

        // Ajax call
        $.get('/shop/get_price/' + productId + '/' + productQty + '?time=' + date.getTime()).done(function (data) {

            if (typeof data != 'undefined' /*&& data != 0*/) {
                // Set HTML
                $productItem.text(setCurrency.processCurrency((data * 1) / productQty));

                // Set data attr
                $productItem.data('price', (data * 1) / productQty);

                // Hide any spinners
                $productItem.next('.js-ajax-spinner').removeClass('ajax-spinner-show');

            } else {
                $(this).text('Price Unavailable');
            }

            //Hide the spinner
            $(this).next('.js-ajax-spinner').removeClass('ajax-spinner-show');

            // Activate add to basket button
            $('.add-to-basket .btn[data-product-id=' + productId + ']').prop('disabled', false);

            // complete
            defer.resolve();

        }).fail(function () {

            //console.log('fetchProductPrice error');

        });

        return defer.promise();

    },
    // Fetch a batch of product prices and insert them into the DOM
    fetchProductPriceBatch: function (productArray) {
        // Defferred object
        var defer = $.Deferred();

        // Get date for caching
        var date = new Date();

        var params = {'products' : productArray};

        $.post('/shop/get_prices' + '?time=' + date.getTime(), params).done(function(data){
        //$.get('/build/json/endpoint-pricelist-success.php', params).done(function(data){
            var response = $.parseJSON(data);
            
            if(response.status === 'success'){
                var priceList = response.products;

                if(priceList && priceList.length){

                    for(var i=0; i<priceList.length; i++){
                        // Find element on page
                        $el = $('.js-get-price[data-product-id=' + priceList[i].id + ']');

                        var price = (priceList[i].amount * 1) / priceList[i].quantity;

                        // Set HTML
                        $el.text(setCurrency.processCurrency(price));

                        // Set data attr
                        $el.data('price', price);

                        // Hide any spinners
                        $el.next('.js-ajax-spinner').removeClass('ajax-spinner-show');

                        // Activate add to basket button
                        $('.add-to-basket .btn[data-product-id=' + priceList[i].id + ']').prop('disabled', false);

                    }
                }

                // complete
                defer.resolve();
            }

            if(response.status === 'error'){
                console.log('fetchProductPriceBatch error');
            }

        }).fail(function () {

            //console.log('fetchProductPrice error');

        });

        return defer.promise();
    },
    // Enable any delete product buttons
    enableDel: function () {
        $('.icon-del').removeClass('disabled');
    }
}

// ============================================== Basket Pricing functions  //

// To handle the prices and events on the basket page

var basketPrices = {
    voucherDiscountTotal: 0,
    taxesTotal: 0,
    taxrate: 0,
    deliveryTotal: null,
    basketTotal: 0,

    init: function () {
        var me = basketPrices;

        // This array will store the references to the products we want to update
        var changedIds = [];

        // Update product array
        // We need to send buildLinePriceList an array of products to update
        var updateChangedIds = function (thisId) {
            var found = $.inArray(thisId, changedIds);
            if (found == -1) {//if not already in Array
                changedIds.push(thisId);
            }
        };

        // Before updating checkout prices and totals
        var checkoutUpdateBasketPre = function () {
            //console.log('checkoutUpdateBasketPre');
            // Disable basket change quantity while we get prices
            $('.js-quantity-edit').prop('disabled', true);
            //
            if (changedIds.length > 0) {
                // only update the prices which have have their quantities changed
                checkoutUpdateBasket();
            } else {
                // Add all prices in checkout to array
                $('.product-list .js-get-price').each(function () {
                    changedIds.push($(this).data('product-id'));
                });
                // Update all prices
                checkoutUpdateBasket();
            }
        }

        // Update checkout prices and totals
        var checkoutUpdateBasket = function () {
            //console.log('checkoutUpdateBasket');
            // Build array of product dom objects
            var productObjs = [];

            // Set changed fields back to 'pending'
            for (var i = 0; i < changedIds.length; i++) {
                // Single price
                $('.product-list .js-get-price[data-product-id=' + changedIds[i] + ']').html('')
                    .next('.js-ajax-spinner').addClass('ajax-spinner-show');

                // Add single price to array to send to
                productObjs.push($('.product-list .js-get-price[data-product-id=' + changedIds[i] + ']'));

                // Total price
                $('.product-list .js-line-item-total[data-product-id=' + changedIds[i] + ']').html('')
                    .next('.js-ajax-spinner').addClass('ajax-spinner-show');
            }

            // Set totals back to 'pending'
            $('.js-basket-total, .js-basket-voucher-discount, .js-subtotal').html('')
                .next('.js-ajax-spinner').addClass('ajax-spinner-show');
            //
            //
            // Array containing Quantities
            // First create object array
            var productArray = [];
            for (var i = 0; i < changedIds.length; i++) {
                var thisQty = $('.js-quantity-edit[data-product-id=' + changedIds[i] + ']').val();
                // remove everything except numerical and decimal
                thisQty = thisQty.replace(/[^0-9.]/g, "");
                // remove decimal
                if (thisQty != '') {
                    thisQty = Math.floor(thisQty);
                }
                //
                var thisObj = {'id': changedIds[i], 'quantity': thisQty}
                productArray.push(thisObj);
            }

            // Rebuild individual prices that may be quantity dependant
            // We want to run each ajax call in series
            // so we build an array of functions
            var getProductsDeferred = [];
            //

            for (var i = 0; i < productObjs.length; i++) {
                updatePagePrices.fetchProductPrice(productObjs[i])
                //getProductsDeferred.push(updatePagePrices.fetchProductPrice(productObjs[i]));
            }

            // Run all the functions in the array and when complete pass done up the chain
            /*$.when.apply($, getProductsDeferred)
                .done(function () {
            });*/


            //updatePagePrices.buildQtyPriceList();

            // Get new prices and run totals queue
            me.buildLinePriceList(changedIds)
                .then(addToBasket.processArray(productArray, true))// Update basket + Overwrite the row
                .then(me.updateVoucherDiscount) // Voucher discount total may be dependant on basket totals
                //
                .then(me.calculateBasketSubTotal).done(function () {
                //console.log('all prices done');
                // Reset array for next time
                changedIds = [];
                // update mini basket
                miniBasket.update(false, false); // showAfter, hideAfter
            });
        }


        // Basket quantity change event
        $('.page-basket').on('keyup', '.js-quantity-edit', function (e) {
            // Need to update the quantity on the price so it can be accessed
            // ensure is integer
            var val = $(this).val();
            // remove everything except numerical and decimal
            val = val.replace(/[^0-9.]/g, "");
            // remove decimal
            if (val != '') {
                val = Math.floor(val);
            }
            //
            $(this).closest('tr').find('.js-get-price').data('product-qty', val);
        });
        // Basket quantity change event
        $('.page-basket').on('change', '.js-quantity-edit', function (e) {
            e.preventDefault();
            updateChangedIds($(this).data('product-id'));
            checkoutUpdateBasketPre();
        });

        // when 'update basket' is clicked get new values for changed items and update totals
        $('.page-basket').on('click', '.js-btn-update-basket', function (e) {
            e.preventDefault();
            //
            checkoutUpdateBasketPre();
        });

        // when 'checkout' is clicked we want to update basket in case user didn't click 'update basket'
        $('.page-basket').on('click', '.js-btn-checkout', function (e) {
            if (changedIds.length > 0) {
                e.preventDefault();
                //
                // @TODO check that this is getting fired properly!
                //
                // page we want to move to
                var urlPath = $(this).attr('href');
                var productArray = [];
                for (var i = 0; i < changedIds.length; i++) {
                    var thisQty = $('.js-quantity-edit[data-product-id=' + changedIds[i] + ']').val();
                    // remove everything except numerical and decimal
                    thisQty = thisQty.replace(/[^0-9.]/g, "");
                    // remove decimal
                    if (thisQty != '') {
                        thisQty = Math.floor(thisQty);
                    }
                    //
                    var thisObj = {'id': changedIds[i], 'quantity': thisQty}
                    productArray.push(thisObj);
                }
                // AJAX call
                addToBasket.processArray(productArray, true)
                    .then(function () {
                        window.location.href = urlPath;
                    });
            }
        });

        // After row has been removed
        var afterRemove = function ($row, last) {

            // Set quantity to zero
            $row.find('.js-quantity-edit').val(0);
            // Update basket events
            // we're repeating this just in case the row onchange doesn't trigger in time
            var thisId = $row.find('.js-quantity-edit').data('product-id');
            updateChangedIds(thisId);

            // Create a product array for 'addToBasket'
            var productArray = [{'id': thisId, 'quantity': 0}];

            // Remove row from dom
            if (!last) {
                $row.remove();
            }

            // Get new prices and run totals queue
            me.buildLinePriceList(changedIds)
                .then(addToBasket.processArray(productArray, true))// Update basket + Overwrite the row
                .then(me.updateVoucherDiscount) // Voucher discount total may be dependant on basket totals
                .then(me.calculateBasketSubTotal).done(function () {
                //console.log('all prices done');
                // Reset array for next time
                changedIds = [];
                // Update mini basket
                miniBasket.update(false, false); // showAfter, hideAfter
            });
        }


        // Remove item row button
        $('.page-basket').on('click', '.icon-del', function (e) {
            //delete a row from the mini basket.
            var $row = $(this).closest('tr');
            var totalRows = $(this).closest('tbody').find('tr').length;

            // Set totals back to 'pending'
            $('.js-basket-total, .js-subtotal').html('')
                .next('.js-ajax-spinner').addClass('ajax-spinner-show');

            // Is a bit complicated to slide up a table row
            $row.fadeTo(200, 0.00, function () { //fade
                if (totalRows > 1) {
                    // Slide up row
                    $.when($row.children('td, th')
                        .animate({paddingTop: 0, paddingBottom: 0}, 300)
                        .wrapInner('<div />')
                        .children()
                        .slideUp(300)).then(function () {
                        // Use 'when' to fire this once
                        afterRemove($row);
                    });
                } else {
                    // Don't slide up row
                    afterRemove($row, true);
                }
            });

            //
            e.preventDefault();
            e.stopPropagation();
        });

        // Get all lineItem prices on page (based on quantity) and calculate totals after
        me.buildLinePriceList()
            .then(me.updateVoucherDiscount)
            .then(me.calculateBasketSubTotal).done(function () {
            //console.log('all basket prices done');
        });

    },
    addToBasketRow: function (productId, productQty) {
        //console.log('productId - '+productId);
        var defer = $.Deferred();

        $.get('/shop/add_item_to_checkout/' + productId).done(function (data) {

            var response = $.parseJSON(data);

            if (response.status == 'success') {
                // add new row of HTML to basket
                $('.product-list tbody').append(response.html);

                // Trigger price update
                var $row = $('.product-list tbody tr:last');
                $row.find('.js-quantity-edit').trigger('change');

                // Activate remove button
                $row.find('.icon-del').removeClass('disabled');

                // Resolve promise
                defer.resolve();
            }

        }).fail(function () {
            //console.log('addToBasketRow error');
        });

        // Return deferred promise
        return defer.promise();

    },
    buildLinePriceList: function (productArray) {
        // Get lineItem prices based on quantity
        var me = basketPrices;

        // Deferred object
        var defer = $.Deferred();

        // Build array of product IDS
        var productIds = [];
        //
        var addToArray = function (productId) {
            var quantity = $('.js-quantity-edit[data-product-id=' + productId + ']').val();
            // create array of objects containing ids and quantities
            var productObj = {'id': productId, 'quantity': quantity};
            productIds.push(productObj);
        }
        //
        if (productArray) {
            // Create array from the specific items passed in
            for (var i = 0; i <= productArray.length; i++) {
                addToArray(productArray[i]);
            }
        } else {
            // Create array of all items on page
            $('.js-line-item-total').each(function () {
                var productId = $(this).data('product-id');
                addToArray(productId);
            });
        }


        // Build an array of functions
        var getProductsDeferred = [];
        //
        for (var i = 0; i < productIds.length; i++) {
            getProductsDeferred.push(me.fetchLineItemTotal(productIds[i].id, productIds[i].quantity));
        }

        // Run functions and when complete pass done up the chain
        $.when.apply($, getProductsDeferred)
            .done(function () {
                defer.resolve();
            });

        // Return deferred promise
        return defer.promise();

    },
    fetchLineItemTotal: function (productId, productQty) {
        // Get lineItem totals based on quantity

        // Deferred object
        var defer = $.Deferred();

        // Get date for caching
        var date = new Date();

        // Ajax call based on product ID
        $.get('/shop/get_price/' + productId + '/' + productQty + '?time=' + date.getTime()).done(function (data) {

            //Populate the price of all examples of this single product on the page
            $('.js-line-item-total[data-product-id=' + productId + ']').each(function (i) {
                if (typeof data != 'undefined' /*&& data != 0*/) {
                    $(this).text(setCurrency.processCurrency(data));
                    $(this).first().data('price', data * 1);
                } else {
                    $(this).text('Unavailable');
                }
                //Hide the spinner
                $(this).next('.js-ajax-spinner').removeClass('ajax-spinner-show');
            });

            //
            defer.resolve();

        }).fail(function () {
            //console.log('fetchLineItemTotal error');
        });

        return defer.promise();
    },

    updateVoucherDiscount: function () {
        var me = basketPrices;

        // Deferred object
        var defer = $.Deferred();

        $.get('/shop/json_get_discount_amount/').done(function (data) {

            data = JSON.parse(data);

            if (typeof data.amount != 'undefined') {
                if (data.amount > 0) {
                    me.voucherDiscountTotal = (data.amount * 1);
                    $('.js-basket-voucher-discount').text('-£' + me.voucherDiscountTotal.toFixed(2))
                        .next('.js-ajax-spinner').removeClass('ajax-spinner-show')
                        .closest('li').show();
                } else {
                    $('.js-basket-voucher-discount').closest('li').hide();
                }
            }

            //console.log('updateVoucherDiscount done');

            defer.resolve();

        }).fail(function () {
            //console.log('fetchLineItemTotal error');
        });

        return defer.promise();

    },
    /**
     * Calculate the tax payable on the basket
     *
     * @param object deliveryValues Object containing all of the delivery variables the server needs for calculation
     * @returns {unresolved}
     */
    updateTaxes: function (vatCountryId, postCode, deliveryCountryId, deliveryoptionId) {
        var me = basketPrices;
        // Deferred object
        var defer = $.Deferred();

        postcode = postCode.replace(/\s+/g, '');

        // Send 'null' instead of blank segments
        vatCountryId = !vatCountryId ? 'null' : vatCountryId;
        postcode = !postcode ? 'null' : postcode;
        deliveryCountryId = !deliveryCountryId ? 'null' : deliveryCountryId;
        deliveryoptionId = !deliveryoptionId ? 'null' : deliveryoptionId;

        //Add the VAT country ID onto the url (just billing not shipping)
        var url = '/shop/json_get_tax_amount/' + vatCountryId + '/' + postcode + '/' + deliveryCountryId + '/' + deliveryoptionId;

        // Process the VAT after server response
        var processVat = function (vatAmount) {
            me.taxesTotal = vatAmount * 1;

            var thisText = setCurrency.processCurrency(me.taxesTotal);

            if (me.taxesTotal <= 0) {
                thisText = 'N/A';
            }

            $('.js-basket-tax').data('price', me.taxesTotal)
                .text(thisText)
                .next('.js-ajax-spinner').removeClass('ajax-spinner-show');
        };


        // Call server for VAT amount
        $.get(url).done(function (amount) {

            if (amount) {
                processVat(amount);
            }

            //console.log('updateTaxes done');

            //  end promise
            defer.resolve();

        }).fail(function () {
            console.log('updateTaxes error');
        });

        return defer.promise();

    },

    /**
     * Update the delivery method based on selections made on address form.
     *
     * @param string postcode
     * @param int countryId
     * @param int deliveryoptionId
     * @returns {unresolved}
     */
    updateDeliveryMethod: function (postcode, countryId, ddeliveryoptionId, excludeDeliverySelect) {
        var me = basketPrices;

        // Defferred object
        var defer = $.Deferred();

        // [PS-353] Present delivery option based on postcode entered
        // We don't want this to happen if the element triggering this function was the deliveryoption_id select (else we have a loop)
        if (!excludeDeliverySelect) {
            var $deliverySelect = $('.js-page-checkout').find('select[name=deliveryoption_id]').first();
            $deliverySelect.prop('disabled', true).trigger('chosen:updated');
            //
            //disable select while thinking
            $.get('/shop/json_get_delivery_options/' + postcode).done(function (jsonData) {

                var data = JSON.parse(jsonData);

                if (typeof data.html != 'undefined') {
                    // Update delivery options select
                    $deliverySelect.html(data.html);
                }

                // Enable and update whatever happens
                $deliverySelect.prop('disabled', false).trigger('chosen:updated');

                // update delivery Warning?
                checkout.deliveryWarning($('.js-page-checkout'));

                defer.resolve();

            }).fail(function () {
                console.log('updateDeliveryMethod error');
            });
        } else {
            defer.resolve();
        }

        return defer.promise();

    },


    /**
     * Update the delivery costs based on selections made on address form.
     *
     * @param string postcode
     * @param int countryId
     * @param int deliveryoptionId
     * @returns {unresolved}
     */
    updateDelivery: function (postcode, countryId, ddeliveryoptionId) {

        var me = basketPrices;

        // Defferred object
        var defer = $.Deferred();

        var deliveryoptionId = $('select[name=deliveryoption_id]').first().val();

        // Send 'null' instead of blank segments
        postcode = !postcode ? 'null' : postcode;
        countryId = !countryId ? 'null' : countryId;
        deliveryoptionId = !deliveryoptionId ? 'null' : deliveryoptionId;

        //if (postcode && postcode != '') { //[PS-287]
        postcode = postcode.replace(/\s+/g, '');

        // Get the delivery amount based on country / postcode / chosen delivery option
        $.get('/shop/json_get_delivery_amount/' + postcode + '/' + countryId + '/' + deliveryoptionId).done(function (jsonData) {

            var data = JSON.parse(jsonData);

            if (typeof data.amount != 'undefined') {

                // null = 'TBC'   0 = 'Free'
                var thisText = 'TBC';

                if (data.amount != null) {
                    //
                    me.deliveryTotal = data.amount * 1;
                    //
                    if (me.deliveryTotal > 0) {
                        thisText = setCurrency.processCurrency(me.deliveryTotal);
                    } else {
                        thisText = 'Free';
                    }

                } else {
                    me.deliveryTotal = null;
                }

                // Set delivery text in dom and remove spinners
                $('.js-delivery-total').data('price', me.deliveryTotal)
                    .text(thisText)
                    .next('.js-ajax-spinner').removeClass('ajax-spinner-show');

            } else {

            }

            //console.log('updateDelivery done');

            defer.resolve();

        }).fail(function () {
            console.log('updateDelivery error');
        });


        /*} else { //[PS-287]

            defer.resolve();

            $('.js-delivery-total').data('price', 0)
                    .text('TBC')
                    .next('.js-ajax-spinner').removeClass('ajax-spinner-show');

        }*/ //[PS-287]

        return defer.promise();

    },

    /**
     * Get the subtotal value of the basket.
     *
     * @returns {unresolved}
     */
    calculateBasketSubTotal: function () {
        var me = basketPrices;

        // Defferred object
        var defer = $.Deferred();

        $.get('/shop/json_get_basket_subtotal/').done(function (data) {

            data = JSON.parse(data);

            if (typeof data.amount != 'undefined') {

                if (data.amount == 0) {
                    $('.js-basket-is-empty').show();
                    $('.js-basket-not-empty').hide();

                } else {
                    $('.js-basket-is-empty').hide();
                    $('.js-basket-not-empty').show();
                }

                //
                me.basketTotal = data.amount * 1;

                // Update Basket subtotal
                $('.js-basket-total').data('price', me.basketTotal)
                    .text(setCurrency.processCurrency(me.basketTotal))
                    .next('.js-ajax-spinner').removeClass('ajax-spinner-show');

                // Update main subtotal
                var subTotal = (me.basketTotal - me.voucherDiscountTotal);
                if (!isNaN(subTotal)) {
                    $('.js-subtotal').data('price', subTotal)
                        .text(setCurrency.processCurrency(subTotal))
                        .next('.js-ajax-spinner').removeClass('ajax-spinner-show');
                }

                // If there is a 'basket < x' message show it
                var $basketSubtotalFlash = $('.js-basket-subtotal-flash');
                if ($basketSubtotalFlash.length) {
                    var messageSubtotal = parseInt($basketSubtotalFlash.data('message-subtotal'));
                    if (me.basketTotal < messageSubtotal) {
                        if ($basketSubtotalFlash.is(':hidden')) {
                            $basketSubtotalFlash.css({'opacity': 0}).show().animate({opacity: 1}, 300);
                        }
                    } else {
                        if ($basketSubtotalFlash.is(':visible')) {
                            $basketSubtotalFlash.animate({opacity: 0}, 300, function () {
                                $basketSubtotalFlash.hide();
                            });
                        }
                    }
                }

            }

            // Activate basket change quantity field
            $('.js-quantity-edit').prop('disabled', false);

            //console.log('calculateBasketSubTotal done');

            defer.resolve();

        }).fail(function () {
            //console.log('calculateBasketSubTotal error');
        });

        return defer.promise();

    },
    
    calculateGrandTotal: function () {
        var me = basketPrices;

        // Deferred object
        var defer = $.Deferred();

        var grandTotal = $('.js-subtotal').data('price') + $('.js-delivery-total').data('price') + $('.js-basket-tax').data('price');

        if (!isNaN(grandTotal)) {
            $('.js-grandtotal').data('price', grandTotal)
                .text(setCurrency.processCurrency(grandTotal))
                .next('.js-ajax-spinner').removeClass('ajax-spinner-show');
        }

        //console.log('calculateGrandTotal done');

        defer.resolve();

        return defer.promise();

    }

}


var basketSoftware = {
    init: function ($target) {
        var me = basketSoftware;

        // Activate popup
        //js-software-mpop

        $('.js-software-modal-open').magnificPopup({
            type: 'inline',
            removalDelay: 500, //delay removal by X to allow out-animation
            overflowY: 'scroll',
            mainClass: 'mfp-zoom-in',
            callbacks: {
                beforeOpen: function () {
                    // Process form before opening
                    // Clear all tickboxes
                    $target.find(':checkbox').prop('checked', false).prop('disabled', false);

                    // If exists in basket then tick box
                    $('.product-list .js-quantity-edit').each(function () {
                        var productId = $(this).data('product-id');
                        $target.find(':checkbox[data-product-id="' + productId + '"]').prop('checked', true).prop('disabled', true);
                    });
                }
            }
        });

        // Submit Form
        $target.find('.js-software-form-btn-submit').click(function (e) {
            e.preventDefault();
            // Build list of products to add
            var addProductIds = [];
            $target.find('input:checked').each(function (i) {
                // Check product isn't already in basket
                var productId = $(this).data('product-id');
                if ($('.product-list .js-quantity-edit[data-product-id="' + productId + '"]').length < 1) {
                    addProductIds.push(productId);
                }
            });
            //console.log(addProductIds);
            // add products
            if (addProductIds.length > 0) {
                me.addProductsToBasket($target, addProductIds);
            }
            // Build list of products to remove
            var removeProductIds = [];
            $target.find('input:not(:checked)').each(function (i) {
                removeProductIds.push($(this).data('product-id'));
            });

            // Close Modal
            $.magnificPopup.close();

        });

        // close popup
        /*$target.find('.js-software-form-btn-close').click(function(e){
            e.preventDefault();
            $('.js-software-modal-open').magnificPopup('close');
        });*/

    },
    addProductsToBasket: function ($target, productIds) {
        var me = basketSoftware;
        // Some fake products comment out this line to use the real products:
        //productIds = [2584];
        // We want to run each ajax call in series
        // so we build an array of functions
        var addProductsDeferred = [];
        //
        for (var i = 0; i < productIds.length; i++) {
            addProductsDeferred.push(basketPrices.addToBasketRow(productIds[i]));
        }

        // Run all the functions in the array and when complete pass done up the chain
        $.when.apply($, addProductsDeferred)
            .done(function () {
                //console.log('All products added');
            });

    }
}
// ============================================== Voucher Code here  //

var processVouchers = {
    init: function () {
        var me = processVouchers;

        // Voucher add button
        $('.page-basket').on('click', '.js-voucher-btn', function (e) {
            e.preventDefault();
            e.stopPropagation();
            $(this).blur();

            if ($('.js-voucher-input').val()) {
                processVouchers.addVoucher($('.js-voucher-input'));
            } else {
                //console.log('No voucher code')
            }

        });

        me.getVouchers();

    },
    getVouchers: function () {

        var $target = $('.js-voucher-message');

        if ($target.length) {

            $.get('/shop/json_get_vouchers/').done(function (data) {

                data = JSON.parse(data);

                if (data) {
                    var voucherText = '';
                    $(data).each(function (index) {
                        voucherText += data[index] + '<br>';
                    });
                    //
                    $target.find('.js-voucher-list').html(voucherText);
                    $target.show();
                }

            });

        }

    },
    addVoucher: function ($target) {
        var me = processVouchers;
        //
        var voucherCode = $target.val();
        $target.val('');
        //
        var $voucherFlash = $('.voucher-flash');
        $voucherFlash.hide();
        //
        $.get('shop/json_add_voucher/' + voucherCode).done(function (data) {
            data = $.parseJSON(data);
            if (data.type == 'error') {
                //$('#voucher-msg').text(data.msg);
                $voucherFlash.removeClass('flash-success').addClass('flash-error');
                $voucherFlash.find('p').text(data.msg);
                $voucherFlash.fadeIn(300);
            } else {
                $voucherFlash.removeClass('flash-error').addClass('flash-success');
                $voucherFlash.find('p').html('<strong>Voucher Added:</strong> ' + voucherCode);
                $voucherFlash.fadeIn(300);
                // Update page
                me.getVouchers();
                // Set totals back to 'pending'
                $('.js-basket-voucher-discount, .js-subtotal').html('')
                    .next('.js-ajax-spinner').addClass('ajax-spinner-show');
                //
                basketPrices.updateVoucherDiscount()
                    .then(basketPrices.calculateBasketSubTotal).done(function () {
                    //console.log('all prices done');
                });
            }
        });
    }
}


// ============================================== Add to Basket functions  //

// This is what actually adds items to the basket in the backend

var addToBasket = {
    init: function () {

        var me = addToBasket;

        // Add to basket click event

        
        $('.main').on('click', '.add-to-basket .btn', function (e) {
            e.preventDefault();

            // [PS-660] - following should be commented out to revert

            /*var $modal = $('#shop-disabled-modal');

            if($modal.length) {

                $.magnificPopup.open({
                    items: {
                        src: $modal
                    },
                    type: 'inline',
                    removalDelay: 500, //delay removal by X to allow out-animation
                    overflowY: 'scroll',
                    mainClass: 'mfp-zoom-in',
                });

            }*/
            
            // [PS-660] - following is commented out to disable the shop


                $('.message-flash').show();

                // Get product ID
                var thisId = $(this).data('product-id');

                // Get quantity from input
                var thisQuantity = 1;
                //
                if (typeof $('#product-' + thisId + '-quantity').val() != 'undefined') {
                    thisQuantity = $('#product-' + thisId + '-quantity').val() * 1;
                }

                // Get current price
                //var thisPrice = $('.js-get-price[data-product-id=' + thisId + ']').first().data('price');

                // Create array of products to pass into addToBasket
                var productArray = [{'id': thisId, 'quantity': thisQuantity}];

                // Add to basket (after checking that price has loaded)
                //if (thisPrice) {

                me.processArray(productArray, false)
                    .then(function () {
                        miniBasket.update(true, true); // showAfter, hideAfter
                    });

                //addToBasket(thisId, thisQuantity, thisPrice);
                //}

                // GA event
                gaEvent(0, 'Shop', 'Add to basket click', $(this).data('ga-event'));


        });

        

        // Reorder product click event
        $('.main').on('click', '.js-re-order .btn', function (e) {
            e.preventDefault();

            // [PS-660] - following should be commented out to revert

            var $modal = $('#shop-disabled-modal');

            if($modal.length) {

                $.magnificPopup.open({
                    items: {
                        src: $modal
                    },
                    type: 'inline',
                    removalDelay: 500, //delay removal by X to allow out-animation
                    overflowY: 'scroll',
                    mainClass: 'mfp-zoom-in',
                });

            }

            // [PS-660] - following is commented out to disable the shop
            /*

                // Get product ID
                var thisId = $(this).data('product-id');

                // Get quantity
                var thisQuantity = $(this).data('quantity');

                // Get current price
                var thisPrice = $('.js-get-price[data-product-id=' + thisId + ']').first().data('price');

                // Create array of products to pass into addToBasket
                var productArray = [{'id': thisId, 'quantity': thisQuantity}];

                // Add to basket (after checking that price has loaded)
                //if (thisPrice) {

                me.processArray(productArray, false)
                    .then(function () {
                        miniBasket.update(true, true); // showAfter, hideAfter
                    });

                //}

            */

        });


    },
    // We pass in an array so we can process multiple items from basket page

    processArray: function (productArray, setQuantity) {
        var me = addToBasket;

        // Defferred object
        var defer = $.Deferred();

        // Build an array of functions so we can return when all are complete
        var getProductsDeferred = [];
        //
        for (var i = 0; i < productArray.length; i++) {
            getProductsDeferred.push(me.addItemToBasket(productArray[i].id, productArray[i].quantity, setQuantity));
        }

        // Run functions and when complete pass done up the chain
        $.when.apply($, getProductsDeferred)
            .done(function () {
                defer.resolve();
            });

        // Return deferred promise
        return defer.promise();

    },
    addItemToBasket: function (productId, thisQuantity, setQuantity) { // setQuantity tells the function to overwrite the row with new qty
        // Defferred object
        var defer = $.Deferred();
        // Do I need to overrite the row with a new quantity
        var thisSetQuantity = '';
        if (setQuantity) {
            thisSetQuantity = '/true'
        }

        // Make the call to the backend to update the current basket contents
        $.get('/shop/update_line_item/' + productId + '/' + thisQuantity + thisSetQuantity).done(function (data) {

            //if (thisPrice) {
            // Add new price to minibasket cache
            //miniBasket.addCachePrice(productId, thisPrice);
            //}
            //
            defer.resolve();

        }).fail(function () {

            //console.log('addItemToBasket error');

        });

        // Return deferred promise
        return defer.promise();

    }

}


// ============================================== Checkout functions  //

var checkout = {
    $target: null,

    init: function (target) {
        var me = checkout;
        me.$target = target;

        // Show login form event
        $('#page-checkout-billing').on('click', '.js-show-login', function (e) {
            var $loginForm = $('#form-checkout-login');
            //
            $loginForm.slideDown();
            //
            $('html, body').animate({
                scrollTop: $loginForm.closest('.account-login').offset().top - 100
            }, 400, 'easeInOutCubic');
            //
            e.preventDefault();
        });

        //Toggle delivery address visibility
        var $deliveryBlock = $('.form-delivery:first');
        //
        me.$target.on('change', '.js-form-switch-delivery input[name=use_billing]', function () {
            //$('.js-form-switch-delivery').on('change', '[name=use_billing]', function () {
            if ($(this).val() == 1) {
                $deliveryBlock.hide();
            } else {
                $deliveryBlock.show();
            }
            // update costs
            me.updateCostSummary();
        });

        // show on page load - guest
        if (me.$target.find('.js-form-switch-delivery input[name=use_billing]').length && me.$target.find('.js-form-switch-delivery input[name=use_billing]:checked').val() != 1) {
            $deliveryBlock.show();
        }

        // show on page load - customer
        if (me.$target.find('.js-form-switch-existing-delivery input[name=delivery_address_id]').length && me.$target.find('.js-form-switch-existing-delivery input[name=delivery_address_id]:checked').val() == 'new_delivery_address') {
            $deliveryBlock.show();
        }

        //Select existing address (when logged in)
        me.$target.on('change', '.js-form-switch-existing-delivery [name=delivery_address_id]', function () {
            //$('.js-form-switch-existing-delivery').on('change', '[name=delivery_address_id]', function () {

            // [PS-294] show new delivery address form
            if ($(this).val() == 'new_delivery_address') {
                $deliveryBlock.show();
            } else {
                $deliveryBlock.hide();
            }

            // update costs
            me.updateCostSummary();
        });

        //If any fields change which could affect delivery then re-calculate costs
        me.$target.on('change', 'input[name=postcode], input[name=shipping_postcode], select[name=country_id], select[name=shipping_country_id], select[name=deliveryoption_id]', function (e) {
            // update costs
            if ($(this).attr('name') == 'deliveryoption_id') {
                // [PS-353] Tel updateCostSummary that the delivery option select triggered this event (so we can exclude it)
                me.updateCostSummary(true);
            } else {
                me.updateCostSummary();
            }

        });


        //Form Validation - checkout login
        $('#form-checkout-login').validate({
            submitHandler: function (form) {
                // Disable the page while we get data
                ajaxDisablePage();

                var email = $(form).find('[name=email]');
                var password = $(form).find('[name=password]');

                $.post('/customers/ajax_login', {
                    username: $(email).val(),
                    password: $(password).val()
                }, function (responseJson) {
                    //
                    var response = $.parseJSON(responseJson);
                    if (response.status == 'success') {
                        //Reload the page - this will update the ui
                        //location.reload();
                        //
                        // Get HTML of Logged in page
                        $.get('/shop/billing', function (data) {

                            $('#js-checkout-form-container').fadeOut(200);

                            $('.account-login').fadeOut(200, function () {

                                var $response = $('<div />').html(data);
                                // Show success message
                                $response.find('.js-login-success').show();
                                // Remove original form
                                $('.account-login').remove();
                                // Put in data and show
                                $('#js-checkout-form-container').html($response.find('#js-checkout-form-container').html()).fadeIn(300, function () {
                                    // Set header content
                                    $('.top-nav').html($response.find('.top-nav').html());
                                    // Initialise any 'Chosen' selects
                                    $('#js-checkout-form-container .select').each(function () {
                                        setSelect.init($(this).find('select:first'));
                                    });
                                    // Initialise submit form validation
                                    me.initFormValidation();
                                    // Get minibasket totals
                                    miniBasket.calculateSubTotal();
                                    // Update prices now we are logged in
                                    me.updateCostSummary();
                                    // Enable page
                                    ajaxEnablePage();
                                });
                            });
                        }, 'html');

                    } else {
                        //Display login error.
                        $(form).find(':submit').blur();
                        $(form).find('.js-login-error').show();
                        ajaxEnablePage();
                    }
                });

            }
        });

        // Initialise submit form validation (we need to run this again if logging in via ajax)
        me.initFormValidation();

        // Update costs
        me.updateCostSummary();

    },
    initFormValidation: function () {

        //Form Validation - checkout address
        $('#form-checkout-addresses')
            .unbind('submit') // Remove any lingering validator instances
            .data('validator', null) // Remove any lingering validator instances
            .validate({
                submitHandler: function (form) {
                    // disable button
                    pendingForm.disable($('.checkout-page-form.form-disable'));
                    pendingButton.show($('.form-submit .btn'));
                    //
                    setTimeout(function () {
                        form.submit();
                    }, 400);

                },
                onfocusout: function (element, event) {
                    //console.log('onfocusout');
                    // We still want the form to validate even if username exists
                    if ($(element).data('check-user')) {
                        if ($(element).valid()) { // If I'm valid check username is available
                            $.post("/users/username_is_available", {username: $(element).val()}, function (data) {
                                if (data == "0") { // username isn't available
                                    $(element).closest('.form-group').find('.note').show();
                                }
                            });
                        }
                        $(element).closest('.form-group').find('.note').hide();
                    }
                }
            });

    },
    // Logic for deciding which fields we are sending to calculate delivery cost
    getDeliveryFields: function () {
        var me = checkout;

        var postcode = null;
        var countryId = null;
        var deliveryOptionId = null;
        var vatCountryId = null;
        var vatNumber = null;

        if ($('.js-form-switch-existing-delivery').length) {
            // If I am logged in

            if ($('.js-form-switch-existing-delivery [name=delivery_address_id]:checked').val() == 'new_delivery_address') {
                // [PS-294] Get data from delivery form
                postcode = me.$target.find('input[name=shipping_postcode]').val();
                countryId = me.$target.find('select[name=shipping_country_id]').val();
            } else {
                // Get values from address select radios
                postcode = $('.js-form-switch-existing-delivery [name=delivery_address_id]:checked').data('postcode');
                countryId = $('.js-form-switch-existing-delivery [name=delivery_address_id]:checked').data('country-id');
            }
        } else {
            // If I am not logged in

            if ($('.js-form-switch-delivery [name=use_billing]:checked').val() == 1) {
                // Get data from billing form
                postcode = me.$target.find('input[name=postcode]').val();
                countryId = me.$target.find('select[name=country_id]').val();
            } else {
                // Get data from delivery form
                postcode = me.$target.find('input[name=shipping_postcode]').val();
                countryId = me.$target.find('select[name=shipping_country_id]').val();
            }
        }

        // Get chosen delivery method
        deliveryOptionId = me.$target.find('select[name=deliveryoption_id]').val();

        // We're calculating VAT based on the Billing country (not delivery)
        vatCountryId = me.$target.find('select[name=country_id]').val();

        // Get vat number
        //vatNumber = me.$target.find('input[name=tax_reference]').val();

        // return an object of values
        return {
            getPostcode: postcode,
            getCountryId: countryId,
            getDeliveryOptionId: deliveryOptionId,
            getVatCountryId: vatCountryId,
            //getVatNumber: vatNumber
        };
    },
    // Update the totals in the checkout cost summary
    updateCostSummary: function (excludeDeliverySelect) {
        var me = checkout;
        //
        // Set totals back to 'pending'
        $('.js-delivery-total, .js-basket-tax, .js-grandtotal').html('')
            .next('.js-ajax-spinner').addClass('ajax-spinner-show');

        // Use 'getDeliveryFields' function to calcualate delievery options (returns object)
        var deliveryValues = me.getDeliveryFields();

        // run queue
        basketPrices.updateVoucherDiscount()
            .then(function () {
                return basketPrices.updateDeliveryMethod(deliveryValues.getPostcode, deliveryValues.getCountryId, deliveryValues.getDeliveryOptionId, excludeDeliverySelect);
            })
            .then(function () {
                return basketPrices.updateDelivery(deliveryValues.getPostcode, deliveryValues.getCountryId, deliveryValues.getDeliveryOptionId);
            })
            .then(function () {
                return basketPrices.calculateBasketSubTotal();
            })
            .then(function () {
                return basketPrices.updateTaxes(deliveryValues.getVatCountryId, deliveryValues.getPostcode, deliveryValues.getCountryId, deliveryValues.getDeliveryOptionId);
            })
            .then(function () {
                return basketPrices.calculateGrandTotal();
            }).done(function () {
            //console.log('all prices done');
        });

        me.deliveryWarning();
    },
    // Determine if a delivery warning needs to be shown (based on country / delivery method selection)
    deliveryWarning: function () {
        var me = checkout;
        var countryId;
        // Get country ID from the relavant field
        if ($('.js-form-switch-existing-delivery').length) {
            // If I am logged in

            if ($('.js-form-switch-existing-delivery [name=delivery_address_id]:checked').val() == 'new_delivery_address') {
                // [PS-294] Get data from delivery form
                countryId = me.$target.find('select[name=shipping_country_id]').val();
            } else {
                // Get values from address select radios
                countryId = $('.js-form-switch-existing-delivery [name=delivery_address_id]:checked').data('country-id');
            }
        } else {
            // If I am not logged in

            if ($('.js-form-switch-delivery [name=use_billing]:checked').val() == 1) {
                // Get data from billing form
                countryId = me.$target.find('select[name=country_id]').val();
            } else {
                // Get data from delivery form
                countryId = me.$target.find('select[name=shipping_country_id]').val();
            }
        }

        // Delivery warning
        var showDeliveryWarning = false;

        // Get UK Country ID
        var countryUkId = me.$target.find('select[name=country_id]').data('uk-id');
        if (countryId && countryId != countryUkId) {
            showDeliveryWarning = true;
        }

        if (showDeliveryWarning) {
            me.$target.find('.js-delivery-warning-message').show();
        } else {
            me.$target.find('.js-delivery-warning-message').hide();
        }

        // Next day cutoff Warning
        var showDeliveryCutoffWarning = false;

        // Get delivery method ID from the relavant field
        var deliveryOptionId = me.$target.find('select[name=deliveryoption_id]').val();
        // Get next day delivery ID
        var nextDayId = me.$target.find('select[name=deliveryoption_id]').data('next-day-id');

        if (deliveryOptionId == nextDayId) {
            me.$target.find('.js-delivery-cutoff-warning-message').show();
        } else {
            me.$target.find('.js-delivery-cutoff-warning-message').hide();
        }


        // Show a redirect message if a non UK country is selected
        me.countryWarning();


    },

    // Determine if a country redirect warning needs to be shown (based on country selection)
    countryWarning: function () {
        var me = checkout;
        var warningHtml = me.$target.find('select[name=country_id]').find(':selected').data('redirect_message');
        // could be on shipping select
        if (warningHtml === null || warningHtml === undefined || warningHtml === '') {
            warningHtml = me.$target.find('select[name=shipping_country_id]').find(':selected').data('redirect_message');
        }
        // show warning and disable button
        if (warningHtml != null && warningHtml != undefined && warningHtml != '') {
            $('.js-country-redirect-warning').html(warningHtml).show();
            $('.js-checkout-submit').prop('disabled', true);
            // Don't need this message
            $('.js-delivery-warning-message').hide();
        } else {
            $('.js-country-redirect-warning').hide();
            $('.js-checkout-submit').prop('disabled', false);
        }
    }


}

// ============================================== Payment functions  //

/**
 * New version of Stripe integration - uses Payment Intents API
 * @type {{init: createPayment.init}}
 */
var createPayment = {

    init: function () {
        var cardholderName = document.getElementById('CardHolder');
        var paymentAmountField = document.getElementById('payment-amount');
        var paymentDescriptionField = document.getElementById('payment-description');
        var receiptEmailField = document.getElementById('receipt-email');
        var cardButton = document.getElementById('submit-payment');

        cardButton.addEventListener('click', function (event) {
            event.preventDefault();
            var form = $(this).closest('form');
            // Disable form
            pendingForm.disable($(form));
            // Disable button
            pendingButton.show($(cardButton));

            var $thisModal = $('#balance-confirm');

            // If on an account page get value from input field and put into popup
            if (paymentAmountField.value > 0) {
                var paymentAmountString = setCurrency.processCurrency(paymentAmountField.value);
                $thisModal.find('.js-confirm-amount').text(paymentAmountString);
            }

            // Was modal submitted or cancelled?
            var submitted = false;

            // Button in modal
            $confirmPaymentBtn = $thisModal.find('.js-confirm-payment').first();

            // Enable modal submit
            pendingButton.hide($confirmPaymentBtn);

            $confirmPaymentBtn.unbind('click').click(function () {
                // don't enable form after close
                submitted = true;

                // Disable modal submit
                pendingButton.show($confirmPaymentBtn);

                // Continue execution
                stripe.createPaymentMethod('card', cardNumberElement, {
                    billing_details: {name: cardholderName != null ? cardholderName.value : null}
                }).then(function (result) {

                    if (result.error) {
                        // Show error in payment form
                        console.log(result);
                        $("#cc-error-message").show();
                        submitted = false;
                    } else {
                        // Otherwise send paymentMethod.id to your server (see Step 2)
                        fetch('/payments/confirm', {
                            method: 'POST',
                            headers: {'Content-Type': 'application/json'},
                            body: JSON.stringify({
                                payment_method_id: result.paymentMethod.id,
                                payment_amount: paymentAmountField.value * 100,
                                receipt_email: receiptEmailField.value,
                                description: paymentDescriptionField.value})
                        }).then(function (result) {
                            // Handle server response (see Step 3)
                            result.json().then(function (json) {
                                //console.log(result);
                                createPayment.handleServerResponse(json, form, cardButton);
                            })
                        });
                    }

                    // close modal
                    $thisModal.magnificPopup('close');

                });

            });

            $.magnificPopup.open({
                items: {
                    src: $thisModal
                },
                type: 'inline',
                removalDelay: 500, //delay removal by X to allow out-animation
                overflowY: 'scroll',
                mainClass: 'mfp-zoom-in',
                //modal: true,
                callbacks: {
                    afterClose: function () {
                        if(!submitted){
                            pendingForm.enable($(form));
                            pendingButton.hide($(cardButton));
                        }
                        $thisModal.find('.js-confirm-payment').unbind('click');
                    }
                }
            });

        });
    },
    //
    handleServerResponse: function (response, form, btn) {
        if (response.error) {
            // Show error from server on payment form
            $("#cc-error-message-text").html(response.error);
            $("#cc-error-message").show();
            pendingForm.enable($(form));
            pendingButton.hide($(btn));
        } else if (response.requires_action) {
            // Use Stripe.js to handle required card action
            stripe.handleCardAction(
                response.payment_intent_client_secret
            ).then(function (result) {
                if (result.error) {
                    // Show error in payment form
                    console.log(result.error);
                    $("#cc-error-message-text").html(result.error.message);
                    $("#cc-error-message").show();
                    // Enable form
                    pendingForm.enable($(form));
                    // Enable submit button
                    pendingButton.hide($(btn));
                } else {
                    // The card action has been handled - the PaymentIntent can be confirmed again on the server
                    fetch('/payments/confirm', {
                        method: 'POST',
                        headers: {'Content-Type': 'application/json'},
                        body: JSON.stringify({payment_intent_id: result.paymentIntent.id})
                    }).then(function (confirmResult) {
                        return confirmResult.json();
                    }).then(function(confirmResult) {
                        createPayment.handleServerResponse(confirmResult, form, btn);
                    });
                }
            });
        } else {
            console.log('successful payment');
            // Success - submit the form
            $(form).append('<input type="hidden" name="payment_id" value="' + response.payment_id + '">').submit();
        }
    }

};

// ============================================== Address lookup functions  //

var addressLookup = {
    init: function ($target) {
        //Fetch address options for postcode.
        var me = addressLookup;
        //
        var $addressResults = $target.closest('.form-group').find('.js-address-options').first();
        // Init select change
        $addressResults.on('change', '.js-select-address', function (e) {
            me.setAddress($(this).find(':selected'));
            e.preventDefault();
        });
        //
        $target.click(function () {
            var postcode = $target.closest('.form-group').find('input[name="postcode"]').val();
            if (!postcode.length) {
                alert('Please enter your postcode');
            } else {
                me.clearAddress();
                $addressResults.html('<div class="address-lookup-message"><p>Looking up address, please wait...</p></div>');
                //
                $.post('/shop/get_address_options_for_postcode', {postcode: postcode}, function (responseJson) {
                    var response = $.parseJSON(responseJson);
                    if (response['status'] == 'success') {
                        //Render address options returned from api.
                        $addressResults.html(response['html']);
                        // Init Select
                        $addressResults.find('.select').each(function () {
                            setSelect.init($(this).find('select:first'));
                        });

                    } else {
                        //An error occurred when fetching address options from API.
                        $target.closest('.form-group').find('.js-address-options').html('<div class="address-lookup-message"><p>Sorry, our address lookup is unavailable at this time.</p></div>');
                    }
                });
            }
        });
    },
    setAddress: function ($target) {
        //Populate form using selected address.

        // If data attr exists and isn't empty
        if (checkDataAttr($target, 'data-address1')) {
            $('input[name="address1"]').val($target.data('address1'));
        }

        // If data attr exists and isn't empty
        if (checkDataAttr($target, 'data-address2')) {
            $('input[name="address2"]').val($target.data('address2'));
        }

        // If data attr exists and isn't empty
        if (checkDataAttr($target, 'data-city')) {
            $('input[name="city"]').val($target.data('city'));
        }

        //Clear address options.
        $target.closest('.form-group').find('.js-address-options').html('');
    },
    clearAddress: function ($target) {
        $('input[name="address1"]').val('');
        $('input[name="address2"]').val('');
        $('input[name="city"]').val('');
    }
};

// ============================================== DOM Ready  //

$(document).ready(function () {

    // Get currency value from data attribute
    setCurrency.init();

    // Floating minibasket functions
    miniBasket.init();

    // Product pricing functions
    updatePagePrices.init();

    // any add to basket buttons
    addToBasket.init();

    // Add software to basket form
    if ($('.js-basket-software-form').length) {
        basketSoftware.init($('.js-basket-software-form').first());
    }

    //Set payment form functions
    if ($('#form-checkout-payment').length) {
        //takePayment.init($('#form-checkout-payment'));
        createPayment.init();
    }

    // Get any existing vouchers
    if ($('.page-basket').length) {
        processVouchers.init();
    }

    // Get totals for billing page
    if ($('.js-page-checkout').length) {
        checkout.init($('.js-page-checkout'));
    }

    //======================================== Delivery Form Functions ========


    //Form Validation - product review form
    $('#form-review').validate({
        submitHandler: function (form) {
            alert('form review');
        }
    });

    //Address lookup.
    if ($('.js-address-lookup-btn').length) {
        addressLookup.init($('.js-address-lookup-btn'));
    }


});

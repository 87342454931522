/*!

 File: data-attr-check.js
 Author: Ember
 Version: 1.0.1
 JS Dependencies:

 Description:
    Helper function for dealing with the existance / value of data attributes on elements

*/


var checkDataAttr = function($element, thisAttr, checkBlank){
    var result = false;
    if(checkBlank){
        // allows data-attribute and data-attribute="true"  otherwise returns false
        if (typeof $element.attr(thisAttr) !== 'undefined' && $element.attr(thisAttr) != 'false') {
            result = true;
        }
    }else{
        // Default - If data attr exists and isn't empty
        if (typeof $element.attr(thisAttr) !== 'undefined' && $element.attr(thisAttr) != '') {
            result = true;
        }
    }
    return result;
}

/* Usage:

// allows data-attribute and data-attribute="true"  otherwise returns false
if (checkDataAttr($target, 'data-attribute', true)) {

// If data attr exists and isn't empty
if (checkDataAttr($(this), 'data-cell-placeholder')) {

*/

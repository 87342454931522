// ============================================== Easing functions //

jQuery.easing['jswing'] = jQuery.easing['swing'];

jQuery.extend(jQuery.easing,
        {
    def: 'easeOutQuad',
    easeOutQuad: function (x, t, b, c, d) {
        return -c * (t /= d) * (t - 2) + b;
    },
    easeOutCubic: function (x, t, b, c, d) {
        return c * ((t = t / d - 1) * t * t + 1) + b;
    },
    easeInOutCubic: function (x, t, b, c, d) {
        if ((t/=d/2) < 1) return c/2*t*t*t + b;
        return c/2*((t-=2)*t*t + 2) + b;
    },
    easeOutQuart: function (x, t, b, c, d) {
        return -c * ((t = t / d - 1) * t * t * t - 1) + b;
    }

});


// ============================================== Mobile Navigation //

var mobileNav = {
    init: function () {
        var navTarget = $('.header:first');
        var menuTarget = navTarget.find('.main-nav');
        var menuPos = 0;

        var menuHeight = 0;
        var mainMenuHeight = menuTarget.find('.primary-nav').outerHeight();
        var subMenuHeight = menuTarget.find('.secondary-nav').outerHeight();

        menuHeight = mainMenuHeight + subMenuHeight;

        var $menuTargetInner = menuTarget.find('.primary-nav');
        /*menuTarget.find('ul').each(function () {
            menuHeight += $(this).outerHeight();
        });*/
        //var menuHeight = 1000;
        //menuTarget.outerHeight();

        //console.log('menuHeight '+menuHeight);

        // Menu open

        $('.header .icon-menu:first').click(function (e) {
            e.preventDefault();

            if (navTarget.hasClass('mob-nav-open')) {
                // remove height from element
                menuTarget.css({'max-height': 0});
            } else {
                // add height to element
                menuTarget.css({'max-height': menuHeight});
            }
            navTarget.toggleClass('mob-nav-open');
        });

        // Sub menus

        $menuTargetInner.addClass('menu-pos-' + menuPos);

        $menuTargetInner.find('ul').each(function () {
            $(this).prepend('<li class="icon-sub-back-li"><a href="#" class="icon-sub-back">Back<\/a><\/li>');
        });

        $menuTargetInner.on('click', 'a', function (e) {
            // Move forward
            if ($(this).hasClass('icon-sub')) {
                e.preventDefault();
                // Set defaults for main menu
                $menuTargetInner.parent().css({'height': mainMenuHeight, 'max-height': mainMenuHeight});
                // Show relavant sub
                $menuTargetInner.find('ul').hide();
                $(this).parent().find('ul').show();
                $(this).parents('ul').show();
                // Set new height
                var subHeight = $(this).parent().find('ul').outerHeight();
                $menuTargetInner.parent().css({'height': subHeight, 'max-height': subHeight});
                // Outer height
                menuTarget.css({'max-height': subHeight + subMenuHeight});
                // move forwards
                $menuTargetInner.removeClass('menu-pos-' + menuPos);
                menuPos++;
                $menuTargetInner.addClass('menu-pos-' + menuPos);
            }
            // move back
            if ($(this).hasClass('icon-sub-back')) {
                e.preventDefault();
                // Set new height
                if (menuPos == 1) {// if going back to home
                    $menuTargetInner.parent().css({'height': mainMenuHeight, 'max-height': mainMenuHeight});
                    // Outer height
                    menuTarget.css({'max-height': menuHeight});
                } else {
                    var subHeight = $(this).parent().parent().parent().parent().outerHeight();
                    $menuTargetInner.parent().css({'height': subHeight, 'max-height': subHeight});
                    menuTarget.css({'max-height': subHeight + subMenuHeight});
                }
                // move back
                $menuTargetInner.removeClass('menu-pos-' + menuPos);
                menuPos--;
                $menuTargetInner.addClass('menu-pos-' + menuPos);
            }
        });

    },
    destroy: function () {

        var navTarget = $('.header:first');
        var menuTarget = navTarget.find('.main-nav');
        var $menuTargetInner = menuTarget.find('.primary-nav');
        //
        menuTarget.removeAttr("style");
        menuTarget.find('.primary-nav-container').removeAttr("style");
        //
        navTarget.removeClass('mob-nav-open');
        //
        $('.header .icon-menu:first').unbind('click');

        $menuTargetInner.unbind('click');
        $menuTargetInner.find('ul').removeAttr("style");
        //
    }
}

// ============================================== Desktop Navigation //

/*var desktopNav = {
    init: function () {
      $('.main-nav .primary-nav ul').on('mouseover', 'li', function(){
        if($(this).find('.nav-sub-content').length){
            //$(this).parent().addClass('show-sub');
        }
      });
      $('.main-nav .primary-nav ul').on('mouseout', 'li', function(){
        $(this).parent().removeClass('show-sub');
      });

    },
    destroy: function () {

        //
    }
}*/
// ============================================== Search Field //

var searchForm = {
    init: function () {

        var searching = false;
        var timer;

        $('#search-query').on('focus', function () {
            searching = true;
            $('body').addClass('search-engaged');
        }).on('blur', function () {
            timer = setTimeout(function () {
                $('body').removeClass('search-engaged');
                searching = false;
            }, 200);
        });
        //
        $('.main-nav .search:first a').click(function (e) {
            if (!searching) {
                $('#search-query').val('').focus();
            }
            //return false;
            //e.preventDefault();
        });
        //
        $('#search-trigger').click(function (e) {
            clearTimeout(timer);
            searching = true;
            $('#search-query').focus();
        });

    },
    destroy: function () {
        $('body').removeClass('search-engaged');
    }
};

var searchResults = {
    init: function($target){
        var totalVis = 5;
        var totalResults = $target.data('result-total');
        //
        if(totalResults>totalVis){
            $target.find('.search-result:gt('+(totalVis-1)+')').hide();
            //
            var $div = $("<div>", {id: "foo", "class": "a"});
            var $msgDiv = $('<div>', {'class': 'search-result-msg'});
            $msgDiv.html('<p>Showing '+totalVis+' of '+totalResults+' results. <a href="#">Show all</a></p>')
            .on('click', 'a', function(e){
                e.preventDefault();
                $target.find('.search-result').attr('style', '');
                $msgDiv.remove();
            });
            //
            $target.append($msgDiv);

        }
        //$( "td:gt(4)" ).
    }
};

// ============================================== Share window popup  //

var windowPopup = {
    init: function (url, width, height) {

        // Calculate the position of the popup so
        // it’s centered on the screen.
        var left = (screen.width / 2) - (width / 2),
        top = (screen.height / 2) - (height / 2);

        window.open(
            url,
            "",
            "menubar=no,toolbar=no,resizable=yes,scrollbars=yes,width=" + width + ",height=" + height + ",top=" + top + ",left=" + left
        );
    },
    destroy: function () {
    }
}


// ============================================== Select dropdown //


var setSelect = {
    init: function (target) {

        var setSelectedIndex = 0;
        var thisSelectedIndex = target.find("option:selected").index();

        if(thisSelectedIndex>0){
            setSelectedIndex = thisSelectedIndex+1;
        }

        // Chosen needs a placeholder data attr and an empty first option

        var firstOption = target.find('option:eq(0)');

        if(firstOption.attr('value') == null || firstOption.attr('value') == '' ){
            target.attr('data-placeholder', firstOption.text());
            firstOption.remove();
            setSelectedIndex--;
        }

        target.prepend('<option></option>');


        // Select this first option
        target[0].selectedIndex = setSelectedIndex;

        // init chosen
        target.chosen({
            disable_search: false,
            disable_search_threshold: 20,
            width: "100%",
            allow_single_deselect: true
        }).change(function() {
            if(target.prop('required')){
                if(target.closest('form').attr('novalidate')){
                    $(this).valid(); // triggering validator plugin if initialised
                }
            }
        });

        // Color boxes
        if(target.hasClass('select-colour')){
            target.on('chosen:showing_dropdown', function() {
                target.parent().find('.chosen-results li').each(function(i){
                    $(this).addClass('colour-item').prepend('<div class="select-colour-box" style="background-color:' + target.find('option').eq(i+1).data('colour-hex') + ';"></div>');
                });
            });
        }

    },
    destroy: function () {
    }
};


// ============================================== Magnific Popup Inline Content //

var mpop = {
    init: function (target) {
        //var url = target.attr("href")
        //alert(url);
        target.magnificPopup({
            type: 'inline',
            removalDelay: 500, //delay removal by X to allow out-animation
            overflowY: 'scroll',
            mainClass: 'mfp-zoom-in'
        });
    },
    destroy: function () {

    }
};

// ============================================== FAQ list open //


var setListLoad = {
    init: function () {
        // on page load if there is a hash show the relevant item and scroll to it
        if (window.location.hash) {
            var thisContent = $('#' + window.location.hash.substr(1));
            thisContent.find('.faq-content').css({'max-height': 'none'});
            window.scrollTo(0, thisContent.offset().top);
        }
    },
    destroy: function () {
    }
};

var setListMore = {
    init: function (target) {
        target.on('click', '.faq-intro', function (e) {
            e.preventDefault();
            //
            var thisContent = $(this).closest('li');
            var contentHeight = thisContent.find('.faq-content-inner').outerHeight();
            //
            if(thisContent.hasClass('open')){
                // close content
                thisContent.removeClass('open').find('.faq-content').css({'max-height': 0});
                var newText = thisContent.find('.title-link').data('open-text');
                thisContent.find('.title-link').text(newText);
            }else{
                // open content
                thisContent.addClass('open').find('.faq-content').css({'max-height': contentHeight});
                var newText = thisContent.find('.title-link').data('close-text');
                thisContent.find('.title-link').text(newText);
                //
                // Set url hash without scrolling
                var liId = thisContent.attr('id');
                if (history.pushState) {
                    // IE10, Firefox, Chrome, etc.
                    window.history.pushState(null, null, '#' + liId);
                } else {
                    // IE9, IE8, etc
                    window.location.hash = '#!' + liId;
                }
                //
                // Scroll to element
                $('html, body').animate({
                    scrollTop: thisContent.offset().top
                }, 500, 'swing');
            }

        });
    },
    destroy: function () {
    }
};


// ============================================== Main image Carousel  //


var setImgCarousel = {
    init: function ($target) {

        // Initialise Slick Carousel
        if($target.find('li').length > 1){
            $target.slick({
                dots: true,
                autoplay: true,
                autoplaySpeed: 5000,
                infinite: true,
                lazyLoad: 'progressive'
            });
        }
        //

        $target.on('lazyLoaded', function (e, slick, image, imageSource) {
            image.attr('src','');
            var $parentSlide = $(image).closest('.hero-img');
            $parentSlide.css('background-image','url("'+imageSource+'")');
         });

    },
    destroy: function () {
    }
};


// ============================================== Product Reviews Carousel  //


var setReviewCarousel = {
    init: function (target) {

        if(target.find('.product-review-item').length > 1){

            var $reviewNav = $('.product-review-nav');

            // Add arrow nav and 1 of 6 content to DOM
            target.on('init reInit', function (event, slick, currentSlide, nextSlide) {

                $reviewNav.append('<p><a href="#" class="prev">&#8249;</a> <span class="total"><span>'+1+'</span> of '+slick.slideCount+'</span> <a href="#" class="next">&#8250;</a></p>');

                $reviewNav.find('.prev').click(function(e){
                    e.preventDefault();
                    target.slick('slickPrev');
                });

                $reviewNav.find('.next').click(function(e){
                    e.preventDefault();
                    target.slick('slickNext');
                });

                updateReviewNav(slick, currentSlide);

            });

            // On change event
            target.on('afterChange', function (event, slick, currentSlide, nextSlide) {
                updateReviewNav(slick, currentSlide);
            });

            // Update 1 of 6 content
            var updateReviewNav = function(slick, currentSlide){
                var i = (currentSlide ? currentSlide : 0) + 1;
                $reviewNav.find('span.total').html('<span>' + i + '</span> of ' + slick.slideCount);
            }

            // Initialise Slick Carousel
            target.slick({
                dots: false,
                infinite: true,
                adaptiveHeight: true
            });


        }

    },
    destroy: function () {
    }
};


// ============================================== Product Image Viewer  //

var setImgViewer = {
    init: function (target) {
        //
        var imgTarget = target.parent().find('.product-main-image ul');
        var thisDisabled = false;
        //
        var inc = target.find('li').length+10;

        // [PS-496] We can't load images through JS as they are on http and we're on https
        // [PS-496] So lets just stick them into markup
        target.find('a').each(function(i){
            if(i>0){
                var thisHref = $(this).attr('href');
                var img = $('<img />', {
                    src: thisHref,
                    style: 'display:none;'
                });
                img.appendTo(target);
            }
        });

        target.on('click', 'a', function(e){
            e.preventDefault();
            //
            if(!$(this).hasClass('active')){
                if(!thisDisabled){
                    //
                    thisDisabled=true;
                    //
                    target.find('.active').removeClass('active');
                    $(this).addClass('active');
                    //
                    var thisIndex = $(this).parent().index();
                    //
                    if(thisIndex==0){
                        // Show first image again
                        imgTarget.find('li:visible').not(':first').fadeOut('400', function(){
                            // Enable button
                            thisDisabled=false;
                        });
                    }else{
                        // Make sure image is loaded before showing
                        var thisLi = imgTarget.find('li:eq('+thisIndex+')');
                        var thisImg = thisLi.find('img');
                        //
                        //var newSrc = thisImg.data('src')
                        var newSrc = thisImg.data('src') ? thisImg.data('src') : thisImg.attr('src');

                        // [PS-496] We can't load images through JS as they are on http and we're on https
                        if(newSrc){
                            thisImg.attr('src', newSrc);
                        }
                        inc++;
                        thisLi.css({'z-index':inc}).fadeIn('400', function(){
                            // Hide old ones
                            imgTarget.find('li').each(function(i){
                                if (i!=thisIndex && i!=0){
                                    $(this).hide();
                                }
                            });
                            // Enable button
                            thisDisabled=false;
                        });

                        // Load image in background
                        //var $loadImage = $("<img>");
                        //
                        /*
                        var loadImage = new Image();
                        $(loadImage).attr('crossOrigin', '');
                        $.ajax({
                            type: 'get',
                            url : newSrc,
                            contentType: 'image/png',
                            crossDomain: 'true',
                            success: function() {
                                $(loadImage).attr('src', newSrc);
                                console.log('success');
                            },
                            error: function() {
                                console.log('Error loading from S3');
                            }
                        });

                        loadImage.onload = function() {
                            if(newSrc){
                                thisImg.attr('src', newSrc);
                            }
                            inc++;
                            thisLi.css({'z-index':inc}).fadeIn('400', function(){
                                // Hide old ones
                                imgTarget.find('li').each(function(i){
                                    if (i!=thisIndex && i!=0){
                                        $(this).hide();
                                    }
                                });
                                // Enable button
                                thisDisabled=false;
                            });
                        }
                        */
                    }
                }
            }

        });
    },
    destroy: function () {
    }
};


// ============================================== PhotoSwipe Lightbox code //

var setLightBox = {
    lightHtml: '<div class="pswp" tabindex="-1" role="dialog" aria-hidden="true"><div class="pswp__bg"></div><div class="pswp__scroll-wrap"><div class="pswp__container"><div class="pswp__item"></div><div class="pswp__item"></div><div class="pswp__item"></div></div><div class="pswp__ui pswp__ui--hidden"><div class="pswp__top-bar"><div class="pswp__counter"></div><button class="pswp__button pswp__button--close" title="Close (Esc)"></button><button class="pswp__button pswp__button--share" title="Share"></button><button class="pswp__button pswp__button--fs" title="Toggle fullscreen"></button><button class="pswp__button pswp__button--zoom" title="Zoom in/out"></button><div class="pswp__preloader"><div class="pswp__preloader__icn"><div class="pswp__preloader__cut"><div class="pswp__preloader__donut"></div></div></div></div></div><div class="pswp__share-modal pswp__share-modal--hidden pswp__single-tap"><div class="pswp__share-tooltip"></div> </div><button class="pswp__button pswp__button--arrow--left" title="Previous (arrow left)"></button><button class="pswp__button pswp__button--arrow--right" title="Next (arrow right)"></button><div class="pswp__caption"><div class="pswp__caption__center"></div></div></div></div></div>',
    init: function (target) {
        $('body').append(setLightBox.lightHtml);
        //
        var getItems = function () {
            var items = [];
            $(target).each(function (i) {
                var $href = $(this).attr('href'),
                        $caption = $(this).data('caption'),
                        $size = $(this).data('size').split('x'),
                        $width = $size[0],
                        $height = $size[1];

                var item = {
                    src: $href,
                    title: $caption,
                    w: $width,
                    h: $height
                }
                $(this).data('index', i)
                items.push(item);
            });
            return items;
        }
        //
        var items = getItems();
        //
        var $pswp = $('.pswp')[0];
        //
        $(target).on('click', $(this), function (e) {
            e.preventDefault();
            var $clickPic = $(this);

            var $index = $clickPic.data('index');

            var options = {
                index: $index, // Slide to start on
                bgOpacity: 0.9,
                showHideOpacity: true,
                closeOnScroll: false,
                history: false,
                shareEl: false,
                getThumbBoundsFn: function (index) {

                    var offset = $clickPic.offset();
                    var posX = offset.left;
                    var posY = offset.top;

                    var w = $clickPic.width();

                    return {x: posX, y: posY, w: w};
                }
            }

            // Initialize PhotoSwipe
            var lightBox = new PhotoSwipe($pswp, PhotoSwipeUI_Default, items, options);
            lightBox.init();

        });
    },
    destroy: function () {
    }
};

// ============================================== Google map //

var createGmap = {
    init: function (target) {
        if (target.length) {
            var thisLat = target.data('map-lat');
            var thisLong = target.data('map-long');;
            var thisZoom = 15;
            var thisTitle = "";
            var thisAddr = "";
            var thisTitle = target.data('title');
            var thisAddr = target.data('addr');
            var thisMapUrl = target.data('map-url');
            //
            var myMap = {
                bounds: null,
                map: null
            }
            //
            myMap.init = function (selector, latLng, zoom) {
                var myOptions = {
                    zoom: zoom,
                    center: latLng,
                    scrollwheel: false,
                    zoomControl: true,
                    zoomControlOptions: {
                        style: google.maps.ZoomControlStyle.SMALL
                    },
                    backgroundColor: 'none',
                    mapTypeId: google.maps.MapTypeId.ROADMAP
                }
                //
                this.map = new google.maps.Map($(selector)[0], myOptions);
                this.bounds = new google.maps.LatLngBounds();
                // Center marker on resize
                var center;
                function calculateCenter() {
                    center = myMap.map.getCenter();
                }
                //
                google.maps.event.addDomListener(myMap.map, 'click', function () {
                    this.setOptions({scrollwheel: true});
                });
                //
                google.maps.event.addDomListener(myMap.map, 'idle', function () {
                    calculateCenter();
                });
                //
                google.maps.event.addDomListener(window, 'resize', function () {
                    myMap.map.setCenter(center);
                });
            };
            //
            myMap.placeMarkers = function () {
                // create a new LatLng point for the marker
                var point = new google.maps.LatLng(parseFloat(thisLat), parseFloat(thisLong));
                // add marker
                var myIcon = new google.maps.MarkerImage("/assets/front/images/planglow-map-icon.png", null, null, null, new google.maps.Size(34,55));
                //
                var marker = new google.maps.Marker({
                    position: point,
                    animation: google.maps.Animation.DROP,
                    icon: myIcon,
                    map: myMap.map
                });
                // create the tooltip and its text
                var infoWindow = new google.maps.InfoWindow();
                var html = '<h4>' + thisTitle + '<\/h4><p>' + thisAddr + ' <br><a href="' + thisMapUrl + '" target="_blank">Get directions<\/a><\/p>';
                // add a listener to open the tooltip when a user clicks on one of the markers
                google.maps.event.addListener(marker, 'click', function () {
                    infoWindow.setContent(html);
                    infoWindow.open(myMap.map, marker);
                });
            };
            //
            var myLatLng = new google.maps.LatLng(thisLat, thisLong);
            myMap.init(target, myLatLng, thisZoom);
            myMap.placeMarkers();
        }
    },
    destroy: function () {

    }
}


// ============================================== Form Validation defaults  //

// http://garajeando.blogspot.co.uk/2014/08/using-jquery-validation-plugin-with.html
var customValidations = (function() {
    return {
        isDigitsSpaces: function(val) {
            return /^(?=.*\d)[\d ]+$/.test(val); // Check for spaces and at least one number
        }
    }
})();

jQuery.validator.setDefaults({
    focusInvalid: false, // so we can smooth scroll
    ignore: ':hidden:not(.select:visible select:hidden)', // Ignore hidden fields except selects (within visible divs) which might be hidden by Chosen
    errorPlacement: function(error, element) {
        if(element.parent().hasClass('select')) {
            error.insertAfter(element.parent()); // If I'm a chosen select then put error after block
        } else if (element.parent().hasClass('checkbox')) {
            error.insertAfter(element.closest('.form-group').find('.checkbox:last label:first')); // If I'm a custom check then put error after last label
        } else if (element.parent().hasClass('radio')) {
            error.insertAfter(element.closest('.form-group').find('.radio:last label:first')); // If I'm a custom radio then put error after last label
        } else if (element.parent().find('.note').length) {
             error.insertAfter(element.closest('.form-group').find('.note')); // If I have a note then put me after
        } else {
            error.insertAfter(element);
        }
    },
    highlight: function(element, errorClass, validClass) {
        $(element).closest('.form-group').addClass(errorClass).removeClass(validClass);
    },
    unhighlight: function(element, errorClass, validClass) {
        $(element).closest('.form-group').addClass(validClass).removeClass(errorClass);
    },
    invalidHandler: function(form, validator) {
        if (!validator.numberOfInvalids()){
            return;
        }
        $('html, body').animate({
            scrollTop: $(validator.errorList[0].element).offset().top -20
        }, 300, 'easeInOutCubic');
    },
    submitHandler: function (form) {
        form.submit();
    }
});

// Custom method for testing if is digits and spaces
jQuery.validator.addMethod('digitsspaces', function(value) {

  if(customValidations.isDigitsSpaces(value)){
    return true;
  }else{
    jQuery.validator.messages.digitsspaces = 'Not a valid phone number';
    return false;
  }

}, jQuery.validator.messages.digitsspaces);

// [PS-280] Show an error is "test@test" is entered as an email address.
jQuery.validator.methods.email = function( value, element ) {
    return this.optional( element ) || /[a-zA-Z\d-_]+@[a-zA-Z\d-_]+\.[a-zA-Z\d-_]+/.test( value );
}

// ============================================== Submit enquiry  //

var submitAjaxForm = function(target){
    // Show spinner after a short time
    var $spinner = target.find('.form-submit .ajax-spinner');
    var spinnerTime = setTimeout(function(){
        $spinner.css({'display':'inline-block'});
    }, 200);
    //
    // Disable button
    var $submitBtn = target.find('.form-submit .btn');
    $submitBtn.prop('disabled', true);
    //
    // Get submit URL from action or data-ajax-submit (if it exists)
    var url = target.data('ajax-submit') ? target.data('ajax-submit') : target.attr('action');
    //
    //Create empty paramter object
    var params = {};
    //
    // Trigger AJAX response
    params['ajax'] = 1;
    //
    //Fill parameter object with values from form fields
    var formdata = target.serializeArray();
    $(formdata ).each(function(index, obj){
        params[obj.name] = obj.value;
    });
    //
    //Make the ajax call to server with the data
    $.post(url,params,function(data){
        //After completion, react to the response.
        var response = $.parseJSON(data);
        //
        // hide spinner
        clearTimeout(spinnerTime);
        $spinner.hide();
        // Enable button
        $submitBtn.prop('disabled', false);
        // Dom elements
        var $thisContainer = target.find('.form-container:first');
        var $thisContainerInner = $thisContainer.find('.form-container-inner:first');
        var $thisContainerResult = $thisContainer.find('.form-container-result:first');
        var $thisContainerResultExtra = $thisContainer.find('.form-container-result-extra:first');
        //If successful, update the UI to show 'enquiry has been received etc.'
        $thisContainer.css({'height': $thisContainer.outerHeight()});
        //
        // Scroll to top of form
        $('html, body').animate({
            scrollTop: target.offset().top - 110 // Take into account possible floating header
        }, 300, 'easeInOutCubic');
        //
        if(response.status == 'success'){
            // If success fade in success message
            $thisContainerInner.fadeOut(200, function(){
                // If html is returned put it into message
                if(response.html){
                    $thisContainerResult.html(response.html);
                }
                // show message
                $thisContainerResult.fadeIn(300);
                $thisContainerResultExtra.fadeIn(300);
            });
        }else{
            //If error, update UI with error message
            $thisContainerInner.fadeOut(200, function(){
                // If html is returned put it into message
                if(response.html){
                    $thisContainerResult.html(response.html);
                }else{
                    $thisContainerResult.html('<p>There was an error, please try again later</p>');
                }
                // show message
                $thisContainerResult.fadeIn(300);
                $thisContainerResultExtra.fadeIn(300);
            });
        }
    });
}

// ============================================== Sticky Sidebar  //

var setStickyCartSummary = {
    init: function (target) {

        var mainBlock = $('.checkout-page-form:first');
        var sideBlock = $('.checkout-page-summary:first');

        if (mainBlock.height() > sideBlock.height()) {

            sideBlock.height(mainBlock.height());

            target.stick_in_parent({
                offset_top: 20
            });

        }

    },
    destroy: function () {
    }
};

// ============================================== AJAX Disable Page  //


var ajaxDisablePage = function(){
    if(!$('body').hasClass('page-disable')){
        $('body').addClass('page-disable');
    }
};

var ajaxEnablePage = function(){
    $('body').addClass('page-disable-closing');
    // Remove after fading out
    setTimeout(function () {
        $('body').removeClass('page-disable');
        $('body').removeClass('page-disable-closing');
    }, 400);
};

// ============================================== Pending Button with spinner //

var pendingButton = {
    showTimerA:{},
    hideTimerA:{},
    hideTimerB:{},
    show: function ($target) {
        // Disable button and add spinner
        //
        var me = pendingButton;
        // disable button
        $target.blur().trigger('mouseleave').addClass('disabled').prop('disabled', true);
        //
        // clear any timers hanging around
        me.clearTimers();

        // Change padding ready for spinner
        $target.addClass('btn-pending');

        me.showTimerA = setTimeout(function(){
            $target.addClass('btn-pending-show');
        }, 300);

    },
    hide: function ($target, callback) {
        var me = pendingButton;

        // Enable button and remove spinner
        // Going to delay this a bit to allow the spinner to finish opening so user can see feedback
        me.hideTimerA = setTimeout(function(){
            // clear any timers hanging around
            me.clearTimers();
            //
            $target.removeClass('btn-pending-show');
            //
            $target.removeClass('btn-pending');
            //
            me.hideTimerB = setTimeout(function(){
                $target.removeClass('disabled').prop('disabled', false);
                // Run callback
                if (typeof callback === 'function'){
                    callback();
                }
            }, 300);

        }, 400);
    },
    clearTimers: function(){
        var me = pendingButton;
        //
        //clearTimeout(me.showTimerA);
       // clearTimeout(me.hideTimerA);
       // clearTimeout(me.hideTimerB);
    }
};

// ============================================== Pending Form Disabled //

var pendingForm = {
    disable: function ($target) {
        $target.removeClass('fadeout').addClass('disabled');
    },
    enable: function ($target) {
        // We can't use onCSSTransitionEnd as transition is on :before element
        $target.addClass('fadeout');
        setTimeout(function(){
            $target.removeClass('disabled').removeClass('fadeout');
        }, 200);
        //.onCSSTransitionEnd( function(){
           // $target.removeClass('disabled').removeClass('fadeout');
        //});
    }
};

// ============================================== Pending Form Disabled //

var setDeliveryEdit = {
    init: function ($target) {
        var me = setDeliveryEdit;

        var $formContainer = $('.form-delivery').first();

        // Set Text
        var $thisTitle = $formContainer.find('.js-form-delivery-title').first();
        var $thisIntro = $formContainer.find('.js-form-delivery-intro').first();

        $thisTitle.data('add-text', $thisTitle.text());
        $thisIntro.data('add-text', $thisIntro.text());


        // Add new address click
        $target.find('.js-account-new-delivery-btn').click(function(e){
            e.preventDefault();
            // Load our form fields using AJAX
            me.loadAjaxForm($target, $formContainer, null);
        });

        // Edit existing address click
        $target.on('click', '.js-account-edit-delivery-btn', function(e){
            e.preventDefault();
            var addressId = $(this).closest('.address-grid-item').data('address-code');
            // Load our form fields using AJAX
            me.loadAjaxForm($target, $formContainer, addressId);
        });

        // Cancel form
        $formContainer.find('.js-form-delivery-cancel').first().click(function(e){
            e.preventDefault();
            me.closeForm($formContainer);
        });

    },
    loadAjaxForm: function($target, $formContainer, addressId){
        var me = setDeliveryEdit;
        //

        var $resultsContainer = $formContainer.find('.js-form-delivery-results');
        var $resultsContainerInner = $resultsContainer.find('.js-form-delivery-results-inner');
        var $spinner = $resultsContainer.find('.ajax-spinner');
        var $saveButton = $formContainer.find('.js-form-delivery-save');

        // Show outer
        $formContainer.show();

        // scroll to block
        $('html, body').animate({
            scrollTop: $formContainer.offset().top-40
        }, 300);

        // Reset HTML
        $resultsContainerInner.html('');
        $target.find('.js-form-delivery-message').hide();

        // Set the titles text
        var $thisTitle = $formContainer.find('.js-form-delivery-title').first();
        var $thisIntro = $formContainer.find('.js-form-delivery-intro').first();
        if(addressId){
            $thisTitle.text($thisTitle.data('edit-text'));
            $thisIntro.text($thisIntro.data('edit-text'));
        }else{
            $thisTitle.text($thisTitle.data('add-text'));
            $thisIntro.text($thisIntro.data('add-text'));
        }

        // Thinking...
        $spinner.show().fadeTo(0.01, 1);
        $saveButton.addClass('disabled').prop('disabled', true);

        // form URL
        var formUrl = '/customers/get_address_form';

        // Are we editing an existing address?
        if(addressId){
            // Remove trailing slash
            formUrl =  formUrl.replace(/\/$/, "");
            // add ID
            formUrl += '/' + addressId;
        }

        var params;


        // fade in main block
        //$formContainer.show().fadeTo(200, 1, function(){

            // AJAX Call
            $.getJSON(formUrl, params, function (responseJson) {

                // Get the response HTML string and convert it to nodes,
                // that way it can be manipulated before being inserted
                var responseHtml = jQuery.parseHTML(responseJson.html);

                // Keep height
                $resultsContainer.css({'min-height':$resultsContainer.height()});

                // Fade out spinner
                $spinner.fadeTo(200, 0, function(){

                    // stop thinking
                    $saveButton.removeClass('disabled').prop('disabled', false);

                    // Set HTML and hide
                    $resultsContainerInner.slideDown(0.01).fadeTo(0.01, 1).html(responseHtml);

                    // Init any JS fields in form

                    // Init the save button
                    me.initSaveEditForm($target, $formContainer, addressId);

                    // show
                    $resultsContainerInner.hide().slideDown(500, function(){
                        // Focus the first fields
                        $resultsContainerInner.find(':input:visible:enabled:first').focus();
                    });

                });

            });

        //});

    },
    initSaveEditForm: function($target, $formContainer, addressId){
        var me = setDeliveryEdit;

        var $saveButton = $formContainer.find('.js-form-delivery-save');
        var $resultsContainerInner = $formContainer.find('.js-form-delivery-results-inner');

        // Set the click event
        $saveButton.unbind('click').click(function(e){
            e.preventDefault();
            // Post data URL

            var saveFormUrl = '/customers/ajax_submit_address_change_request';

            // Thinking...
            pendingButton.show($saveButton);
            pendingForm.disable($resultsContainerInner);

            // We can't serailize the form in case there is a nested form so need to serialize all individual fields
            var serialisedForm = $resultsContainerInner.find('input[name], textarea[name], select[name]').serializeArray();

            /* add ID of entry to params

            if(addressId){
                // This must be formatted the same format as serializeArray results (name:x, value:y)
                serialisedForm.push({name: 'id', value: addressId});
            }

            console.log(serialisedForm);*/


            // Default AJAX options (serialised data)
            var thisData = $.param(serialisedForm);
            var thisProcessData = true;
            var thisContentType = 'application/x-www-form-urlencoded; charset=UTF-8';

            // AJAX request
            $.ajax({
                url: saveFormUrl,
                data: thisData,
                processData: false,
                contentType: thisContentType,
                type: 'POST',
                success: function(response) {
                    var responseJson = jQuery.parseJSON(response);
                    console.log(responseJson);

                    // Stop thinking
                    pendingForm.enable($resultsContainerInner);
                    pendingButton.hide($saveButton, function(){
                        if(responseJson.status=='success'){
                            // Close form
                            me.closeForm($formContainer);
                            // Show Response message
                            setTimeout(function(){
                                $target.find('.js-form-delivery-message').css({'opacity':0}).show().animate({opacity: 1}, 200);
                            }, 200);

                        }
                    });

                },
                error: function(response) {
                    console.log(response);
                }
            });

        });
    },
    closeForm: function($formContainer){
        var $resultsContainerInner = $formContainer.find('.js-form-delivery-results-inner');

        // Scroll to grid

        $('html, body').animate({
            scrollTop: $('.form-switch-delivery').first().offset().top-40
        }, 300);


        $resultsContainerInner.slideUp(500, function(){
            $formContainer.fadeOut(200);
        });

    }


};


// ============================================== Google Analytics Custom Event Function

var gaEvent = function (inc, eventCategory, eventAction, eventLabel, url) {
    if (window.ga) {
        if (url != undefined) {
            // Redirect to new page after GA Event is logged
            ga('send', 'event', eventCategory, eventAction, eventLabel, {
                'hitCallback': function () {
                    document.location = url;
                },
                nonInteraction: true
            });
            // timer fallback just in case
            setTimeout(function () {
                document.location = url;
            }, 1000);
        } else {
            // Just log the GA Event
            ga('send', 'event', eventCategory, eventAction, eventLabel, {
                nonInteraction: true
            });
        }
    } else {
        // if it's a clicked link just go to the URL (user might have GA blocked)
        if (url != undefined) {
            document.location = url;
        } else {
            // If its a page load we can afford to Repeat a few times until GA is loaded
            if (inc == undefined) {
                inc = 0;
            }
            if (inc < 3) {
                inc++;
                var timer = setTimeout(gaEvent, 1000, inc, eventCategory, eventAction, eventLabel, url);
            }
        }
    }
}

// ============================================== Captcha enable

function enableSubmitBtn(){
    $('.js-recaptcha-submit').prop('disabled', false);
}


// ============================================== Timed Newsletter Popup  //
var setTimedNewsletter = {
    init: function ($target) {

        var secondsToWait = 30;

        var newsletterSubmitted = false;

        $target.find('.js-newsletter-pop-close').on('click', function (e) {
            e.preventDefault();
            $target.magnificPopup('close');
        });

        $target.find('.js-recaptcha-submit').on('click', function () {
            gaEvent(0, 'Timed Popup', 'Timed Newsletter', 'Newsletter Submitted');
            newsletterSubmitted = true;
        });

        // Check for cookie
        if(Cookies.get('seen-newsletter-popup')!='yes'){
            // Start timer
            setTimeout(function(){
                // set cookie
                Cookies.set('seen-newsletter-popup', 'yes', { expires: 30 });

                $.magnificPopup.open({
                    items: {
                        src: $target
                    },
                    type: 'inline',
                    removalDelay: 500, //delay removal by X to allow out-animation
                    overflowY: 'scroll',
                    mainClass: 'mfp-zoom-in',
                    callbacks: {
                        open: function() {
                            // Analytics event
                            gaEvent(0, 'Timed Popup', 'Timed Newsletter', 'Popup open');
                        },
                        close: function () {
                            // Analytics event
                            if(!newsletterSubmitted){
                                gaEvent(0, 'Timed Popup', 'Timed Newsletter', 'Newsletter Refused');
                            }

                        }
                    }
                });

            }, secondsToWait*1000);
        }
    }
};

// ============================================== FORCE INTEGER  //

var setLiveInteger = {
    init: function ($target) {
        var processInput = function(){
            var val = $target.val();
            // remove everything except numerical and decimal
            val = val.replace(/[^0-9.]/g, "");
            // remove decimal
            if(val!=''){
                val = Math.floor(val);
            }
            //
            if (val<1){
                val='';
            }
            //
            $target.val(val);
        }
        //
        $target.on('keyup', function(){
            processInput();
        });
        //
        processInput();
    },
    destroy: function () {
    }
};

// ============================================== //

// DOM READY  //

// ============================================== //


$(document).ready(function () {

    // SVG Fallback
    if (!Modernizr.svg) {
        $('.header img[src$=".svg"], .footer img[src$=".svg"], .summary-block .summary-img img[src$=".svg"]').each(function (index, e) {
            e.src = e.src.replace('.svg', '.png');
        });
    }

    searchForm.init();

    $('.search-result-block').each(function(){
        searchResults.init($(this));
    });

    // Mobile events
    enquire.register("screen and (max-width:720px)", {
        // Triggered when a media query matches.
        match: function () {
            // slight delay to ensure correct element height is recorded
            setTimeout(function () {
                mobileNav.init();
            }, 200);
            //
            //mobileSearch.init();
        },
        // Triggered when the media query transitions
        unmatch: function () {
            mobileNav.destroy();
            //mobileSearch.destroy();
        }
    });

    var colsSummaries;
    var colsFeatures;

    // Desktop events
    enquire.register("screen and (min-width:720px)", {

        // Triggered when a media query matches.
        match : function() {

            //desktopNav.init();

            colsSummaries = $('.summary-item').colConform({
                innerItems: [$('.summary-content-inner'), $('.summary-heading')]
            });

            colsFeatures = $('.cta-feature-item').colConform({
                innerItems: [$('.feature-content'), $('.feature-heading')]
            });

        },

        // Triggered when the media query transitions
        unmatch : function() {

            //desktopNav.destroy();

            colsSummaries.destroy();
            colsFeatures.destroy();

        }

    });

    // Share window popup  //
    $('.js-social-share').on('click', function(e) {
      e.preventDefault();
      gaEvent(0, 'Header', 'Page Share', $(this).data('ga-event'));
      windowPopup.init($(this).attr('href'), 500, 300);
    });

    // Select dropdowns
    $('.select').each(function(){
        setSelect.init($(this).find('select:first'));
    });

    // News category Select change
    // product variant switch URL
    $('.js-news-category').on('change', 'select', function(){
        var url = $(this).find(":selected").val();
        //alert(category);
        //var url = $(this).find(":selected").data('url');
        location.href = url;
    });


    // Modal windows
    $('.mpop').each(function () {
        mpop.init($(this));
    });

    // Main image carousel
    setImgCarousel.init($('.carousel'));

    // Product Reviews carousel
    setReviewCarousel.init($('.product-review-slides'));

    setImgViewer.init($('.product-slideshow'));

    // Photoswipe lightbox
    if ($('.product-main-image').length) {
        setLightBox.init('.product-main-image li a');
    }


    // List show more
    if ($('.faq-list').length) {
        setListLoad.init();
        //
        $('.faq-list').each(function () {
            setListMore.init($(this));
        });
    }

    // google map
    $('.map').each(function(){
        var thisTarget = $(this).attr('id');
        createGmap.init($('#'+thisTarget+''));
    });

    // Sticky cart summary
    if ($('.js-sticky-cart-summary').length) {
        setStickyCartSummary.init($('.js-sticky-cart-summary'));
    }

    // Force integer
    if ($('.js-live-integer').length) {
        $('.js-live-integer').each(function () {
            setLiveInteger.init($(this));
        });
    }


    //Form Validation - newsletter form
    $('#form-newsletter').validate({
        submitHandler: function (form) {
            submitAjaxForm($(form));
        }
    });
    $('#form-newsletter-pop').validate({
        submitHandler: function (form) {
            submitAjaxForm($(form));
        }
    });

    //Form Validation - product review form
    /*$('#form-review').validate({
        submitHandler: function (form) {
            alert('form review');
        }
    });*/


    //Form Validation - general enquiry form
    $('#form-enquiry').validate({
        submitHandler: function (form) {
            submitAjaxForm($(form));
            //alert('form enquiry');
        }
    });

    $('.js-form-validate-submit').each(function(){
        $(this).validate({
            submitHandler: function (form) {
                form.submit();
            }
        });
    });


    //Form Validation - account billing
    $('#form-account-billing').validate({
        submitHandler: function (form) {
            form.submit();
        }
    });


    // product variant switch URL
    $('.js-product-variant').on('change', 'select', function(){
        var url = $(this).find(":selected").data('url');
        location.href = url;
    });

    // Request sample pack form
    $('.js-sample-selection').on('change', 'input', function () {
        var $sampleBlock = $('.js-sample-list');
        //
        $(this).val() == 1 ? $sampleBlock.slideUp(300, function(){$(this).find('.form-control').val('')}) : $sampleBlock.slideDown(300);
    });

    // Form Validation - Request sample pack
    $('#form-request-sample').validate({
        submitHandler: function (form) {
            submitAjaxForm($(form));
        }
    });

    // Add delivery address functions
    if ($('.js-form-delivery-addresses').length) {
        setDeliveryEdit.init($('.js-form-delivery-addresses'));
    }

    // Timed newsletter popup
    if($('.js-timed-newsletter').length){
        setTimedNewsletter.init($('.js-timed-newsletter').first());
    }


    // GA Events

    // Shop button in main nav
    $('.header').on('click', '.js-secondary-nav-shop', function (e) {
        e.preventDefault();
        var thisUrl = $(this).attr('href');
        gaEvent(0, 'Header', 'Main nav shop button click', 'Shop button click', thisUrl);
    });

    // Feature box click
    $('.cta-features').on('click', '.js-feature-item', function (e) {
        e.preventDefault();
        var thisUrl = $(this).attr('href');
        gaEvent(0, 'General Page', 'Feature click', $(this).data('ga-event'), thisUrl);
    });

    // Range summary click
    $('.summary-collections').on('click', '.js-summary-range', function (e) {
        e.preventDefault();
        var thisUrl = $(this).attr('href');
        gaEvent(0, 'General Page', 'Summary collection click', $(this).data('ga-event'), thisUrl);
    });

    //
    $('.page-content').on('click', '.js-ga-event', function (e) {
        var gaEventCategory = $(this).data('ga-event-category') ? $(this).data('ga-event-category') : 'General Page';
        var gaEventAction = $(this).data('ga-event-action') ? $(this).data('ga-event-action') : 'Content Click';
        gaEvent(0, gaEventCategory, gaEventAction, $(this).data('ga-event'));
    });

    // Footer link clicks
    $('.footer').on('click', '.js-email-link', function (e) {
        gaEvent(0, 'Footer', 'Contact click', 'Email link click');
    });
    $('.footer').on('click', '.js-tel-link', function (e) {
        gaEvent(0, 'Footer', 'Contact click', 'Telephone link click');
    });

    // GA Scroll Depth
    jQuery.scrollDepth({
        userTiming: false
    });

    // Trigger lifecycle animation
    if ($('#lifecycle_canvas').length) {
        if (typeof lifecycle_init == 'function') {
            lifecycle_init();
        }
    }

    // Add guest-user email to cookie if necessary
    $('.js-guest-email').change(function (e) {
        if(document.cookie.indexOf("plan_email") === -1){
            var email = $(this).val();
            var emailReg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if(emailReg.test(String(email).toLowerCase())){
                var date = new Date();
                date.setTime(date.getTime() + (90*24*60*60*1000));
                expires = "; expires=" + date.toUTCString();
                document.cookie = "plan_email=" + (email || "")  + expires + "; path=/";
            }
        }
    });



    // Set background image path
    /*
    $('.js-srcset-img').each(function(){
        $(this).on('load', $(this), function(){
            var src = typeof $(this)[0].currentSrc !== 'undefined' ? $(this)[0].currentSrc : $(this)[0].src;
            console.log(src);
        });
        if ($(this).complete) {
            console.log('complete');
        }
    });*/

});
